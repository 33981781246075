/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useChainList, useTplList, useTplStatusList } from '@bo/api';
import { IColumn, useGlobalStyles } from '@bo/hooks';
import { TplListItem, TplListRequest } from '@bo/types';
import { TableList, TableListContentType, BoolLabel, DateView, EmptyList } from '@bo/components';
import { SearchForm } from './SearchForm';

const columns: IColumn[] = [
  { id: 'id', name: 'id', active: true },
  { id: 'token', name: 'token', active: true },
  { id: 'name', name: 'name', active: true },
  { id: 'chain', name: 'chain', active: true },
  { id: 'is_scam', name: 'is scam', active: true, cellProps: { align: 'center' } },
  { id: 'created_at', name: 'created at', active: true, cellProps: { align: 'center' } },
  { id: 'updated_at', name: 'updated at', active: true, cellProps: { align: 'center' } },
];

const dexScreenMap = {
  ETHEREUM: 'https://dexscreener.com/ethereum',
  BASE: 'https://dexscreener.com/base',
};

export const TokenPoolPage = () => {
  const classes = useGlobalStyles();
  const [{ data: items, error, cursor, left }, loading, load] = useTplList();
  const [{ data: statusList }, , loadStatusList] = useTplStatusList();
  const [{ data: chainList }, , loadChainList] = useChainList();

  const [filter, setFilter] = useState<TplListRequest>({ chainList: [], statusList: [] });

  useEffect(() => {
    load({ ...filter });
  }, [filter]);

  useEffect(() => {
    loadChainList();
    loadStatusList();
  }, []);

  const handleRowClick = () => {};

  const content: TableListContentType<TplListItem> = {
    id: (i) => i.id,
    token: (i) => i.token,
    name: (i) => (
      // @ts-expect-error skip
      <a style={{ color: 'white' }} target="_blank" href={`${dexScreenMap[i.chain]}/${i.token}`} rel="noreferrer">
        {i.name}
      </a>
    ),
    chain: (i) => i.chain,
    is_scam: (i) => <BoolLabel value={i.is_scam} />,
    created_at: (i) => <DateView value={i.created_at} />,
    updated_at: (i) => <DateView value={i.updated_at} />,
  };

  if (items.length === 0 && chainList.length > 0 && statusList.length > 0)
    return (
      <>
        <SearchForm statusList={statusList} chainList={chainList} initialValues={filter} setFilter={setFilter} />
        <EmptyList error={error} loading={loading} />
      </>
    );

  return chainList.length > 0 ? (
    <Grid container spacing={3}>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <SearchForm statusList={statusList} chainList={chainList} initialValues={filter} setFilter={setFilter} />
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justifyContent="space-between">
              <Grid item></Grid>
            </Grid>
          </CardActions>
          <TableList<TplListItem> items={items} columns={columns} content={content} onRowClick={handleRowClick} />
        </Paper>
      </Grid>
    </Grid>
  ) : null;
};

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { DateView, InputField, SelectField } from '@bo/components';
import { BacktestItemAddRequest } from '@bo/types';
import { Form, Formik } from 'formik';
import { composeValidators, formikValidator, mustBeNumber, required } from '@bo/validators';
import { useFormStyles } from '@bo/hooks';
import { Button } from '@material-ui/core';
import { backtestChainList } from '../BacktestPage';
import configTemplate from './config-template.json';

interface IProps {
  onSave: (data: BacktestItemAddRequest) => void;
  saving: boolean;
}

const initialValues = {} as BacktestItemAddRequest;

export function BacktestAdd({ onSave, saving }: IProps) {
  const formClasses = useFormStyles();

  const downloadTemplate = () => {
    const text = JSON.stringify(configTemplate, null, 2);
    const element = document.createElement('a');
    element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', 'config_template.json');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: 24 }}>
        <Typography variant="h6" color="primary">
          Add backtest
        </Typography>
      </Grid>

      <Formik
        initialValues={initialValues}
        validate={(values) => {
          return formikValidator({
            chain: required(values.chain),
            eth_amount: composeValidators(values.eth_amount, required, mustBeNumber),
            from_block: composeValidators(values.from_block, required, mustBeNumber),
            to_block: composeValidators(values.to_block, required, mustBeNumber),
            config: required(values.config),
          });
        }}
        onSubmit={(values) => {
          const data = values as BacktestItemAddRequest;
          onSave(data);
        }}
      >
        {({ values, isValid }) => {
          return (
            <Form className={formClasses.root}>
              <div className={formClasses.row}>
                <SelectField
                  name="chain"
                  label="chain"
                  options={backtestChainList.map((v) => ({ value: v, label: v }))}
                  noEmptyOption
                />
              </div>

              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ paddingRight: '5px' }}>Aprox time</div>
                  <DateView value={new Date(values.from_block ? values.from_block * 100000 : 0)} />
                </div>
                <InputField name="from_block" label="from block" />
              </div>
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ paddingRight: '5px' }}>Aprox time</div>
                  <DateView value={new Date(values.from_block ? values.from_block * 100000 : 0)} />
                </div>
                <InputField name="to_block" label="to block" />
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                <span>Add config json here</span>
                <div style={{ paddingLeft: '5px' }}>
                  <a href="https://codebeautify.org/jsonviewer" target="_blank" rel="noreferrer">
                    JsonBeautify
                  </a>
                </div>

                <div style={{ paddingLeft: '5px' }}>
                  <Button variant="outlined" size="small" onClick={downloadTemplate}>
                    Template
                  </Button>
                </div>
              </div>

              <InputField name="config" label="config" />

              <div className={formClasses.actions}>
                <Button type="submit" disabled={saving || !isValid} variant="contained" size="large">
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { SelectField } from '@bo/components';
import { Form, Formik } from 'formik';
import { formikValidator, required } from '@bo/validators';
import { useFormStyles } from '@bo/hooks';
import { Button } from '@material-ui/core';
import { WalletItemUpdateRequest, WalletListItem } from '@bo/types';

interface IProps {
  onSave: (data: WalletItemUpdateRequest) => void;
  saving: boolean;
  item: WalletListItem;
}

export function UpdateWalletPopup({ onSave, saving, item }: IProps) {
  const formClasses = useFormStyles();

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: 24 }}>
        <Typography variant="h6" color="primary">
          Update wallet
        </Typography>
      </Grid>

      <Formik
        initialValues={
          {
            id: item.id,
            auto_buy: item.auto_buy,
            auto_sell: item.auto_sell,
            is_deprecated: item.is_deprecated,
          } as WalletItemUpdateRequest
        }
        validate={(values) => {
          return formikValidator({
            id: required(values.id),
            auto_buy: required(values.auto_buy),
            auto_sell: required(values.auto_sell),
            is_deprecated: required(values.is_deprecated),
          });
        }}
        onSubmit={(values) => {
          const data = { ...values } as WalletItemUpdateRequest;
          onSave(data);
        }}
      >
        {({ isValid }) => {
          return (
            <Form className={formClasses.root}>
              <div className={formClasses.row}>
                <SelectField
                  name="auto_buy"
                  label="auto buy"
                  options={[true, false].map((v) => ({ value: v, label: `${v}` }))}
                  noEmptyOption
                />
              </div>

              <div className={formClasses.row}>
                <SelectField
                  name="auto_sell"
                  label="auto sell"
                  options={[true, false].map((v) => ({ value: v, label: `${v}` }))}
                  noEmptyOption
                />
              </div>

              <div className={formClasses.row}>
                <SelectField
                  name="is_deprecated"
                  label="is deprecated"
                  options={[true, false].map((v) => ({ value: v, label: `${v}` }))}
                  noEmptyOption
                />
              </div>

              <div className={formClasses.actions}>
                <Button type="submit" disabled={saving || !isValid} variant="contained" size="large">
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

import React from 'react';
import { useSnackbar } from 'notistack';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import CopyToClipboard from 'react-copy-to-clipboard';

const useStyles = makeStyles(() => ({
  root: {},
  button: {
    '& svg': {
      width: '18px',
      height: '18px',
    },
  },
}));

interface IProps {
  text: string;
}

export function TextCopy({ text }: IProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <CopyToClipboard text={text} onCopy={() => enqueueSnackbar('Data copied', { variant: 'success' })}>
      <IconButton size="small" className={classes.button}>
        <FileCopyIcon color="inherit" />
      </IconButton>
    </CopyToClipboard>
  );
}

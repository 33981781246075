/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useBacktestList } from '@bo/api';
import { IColumn, useColumns, useGlobalStyles } from '@bo/hooks';
import { BacktestListItem, BacktestListRequest } from '@bo/types';
import { TableList, TableListContentType, DateView, EmptyList, Loader } from '@bo/components';
import { SearchForm } from './SearchForm';
import { Button, TableCell } from '@material-ui/core';
import { BacktestPopup } from './details/BacktestPopup';
import { Waypoint } from 'react-waypoint';
import { ROUTES } from '@bo/constants';
import { useNavigate } from 'react-router';
import { formatPnL } from '../common';

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'id', active: true },
  { id: 'chain', name: 'chain', active: true },
  { id: 'status', name: 'status', active: true },

  { id: 'pnl', name: 'pnl eth', active: true },
  { id: 'from_block', name: 'from block', active: true },
  { id: 'to_block', name: 'to block', active: true },

  { id: 'created_at', name: 'created at', active: true, cellProps: { align: 'center' } },
  { id: 'updated_at', name: 'updated at', active: true, cellProps: { align: 'center' } },
  { id: 'actions', name: 'actions', active: true, cellProps: { align: 'center' } },
];

type BacktestState = { backtest: BacktestListItem; method: string };

export const backtestStatusList = ['NEW', 'SUCCESS', 'FINISHED', 'PAUSE'];
export const backtestChainList = ['ETHEREUM', 'BASE'];

export const BacktestPage = () => {
  const navigate = useNavigate();
  const classes = useGlobalStyles();
  const [columns, ColumnsFilter] = useColumns('backtests', defaultColumns);
  const [{ data: items, error, cursor, left }, loading, load] = useBacktestList();

  const [filter, setFilter] = useState<BacktestListRequest>({});
  const [backtestData, setBacktestData] = useState({} as BacktestState);

  useEffect(() => {
    load({ ...filter });
  }, [filter]);

  const handleRowClick = () => {};

  function setDetails(data: BacktestState) {
    if (data.method === 'open' && data.backtest) {
      navigate(`${ROUTES.MEME_STRATEGY_BACKTEST_ASSET_LIST}/${data.backtest.id}/assets`);
    }

    if (data.backtest) {
      setBacktestData(data);
    } else {
      if (data.method === 'add') {
        setBacktestData(data);
      } else {
        setBacktestData({} as BacktestState);
        load({ ...filter });
      }
    }
  }

  const content: TableListContentType<BacktestListItem> = {
    id: (i) => i.id,
    chain: (i) => i.chain,
    status: (i) => i.status,
    pnl: (i) => formatPnL(i.pnl),
    eth_amount: (i) => i.eth_amount,
    from_block: (i) => i.from_block,
    to_block: (i) => i.to_block,
    created_at: (i) => <DateView value={i.created_at} />,
    updated_at: (i) => <DateView value={i.updated_at} />,
    actions: (i) => (
      <TableCell key={'actions'}>
        <Button
          variant="contained"
          size="small"
          onClick={() => setDetails({ backtest: i, method: 'open' })}
          style={{ marginRight: '10px', height: '17px' }}
        >
          open
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => setDetails({ backtest: i, method: 'update' })}
          style={{ marginRight: '10px', height: '17px' }}
        >
          update
        </Button>
      </TableCell>
    ),
  };

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        {/* <SearchForm initialValues={filter} setFilter={setFilter} /> */}
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button
                  variant="contained"
                  size="medium"
                  onClick={() => setDetails({ method: 'add' } as BacktestState)}
                >
                  Add backtest
                </Button>
              </Grid>
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          <>
            <TableList<BacktestListItem>
              items={items}
              columns={columns}
              content={content}
              onRowClick={handleRowClick}
            />
            {/* <CardIssueCurrencyList items={items} onRowClick={setDetails} columns={columns} /> */}
            {left && cursor && <Waypoint onEnter={() => load({ cursor })} />}
          </>
          <Loader loading={loading} />
        </Paper>
      </Grid>
      {['update', 'add'].includes(backtestData?.method) && (
        <BacktestPopup
          method={backtestData.method}
          item={backtestData.backtest}
          onSave={() => setDetails({} as BacktestState)}
          onClose={() => setDetails({} as BacktestState)}
        />
      )}
    </Grid>
  );
};

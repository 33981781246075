import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PartyModeSharp from '@material-ui/icons/PartyModeSharp';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { ROUTES } from '@bo/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'auto',
  },
  activeItem: {
    background: theme.palette.action.selected,
  },
  expandable: {
    flexDirection: 'column',
    cursor: 'pointer',
    alignItems: 'stretch',
    padding: 0,
  },
  submenu: {
    'marginLeft': -1,
    '& > a': {
      paddingLeft: 74,
    },
  },
}));

export const MainMenu = () => {
  const classes = useStyles();
  const { pathname: url } = useLocation();
  const [submenu, setSubmenu] = useState<string | undefined>(url.split('/')[1]);

  const getActiveClass = (path: string) => (url.includes(path) ? classes.activeItem : '');

  return (
    <List className={classes.root}>
      <ListItem className={classes.expandable}>
        <ListItem
          button
          component={NavLink}
          to={ROUTES.MEME_STRATEGY_DOCS_PATH}
          onClick={() => setSubmenu('meme_strategy')}
        >
          <ListItemIcon>
            <PartyModeSharp />
          </ListItemIcon>
          <ListItemText primary="Meme Strategy" />
        </ListItem>
        {submenu === 'meme_strategy' && (
          <div className={classes.submenu}>
            <ListItem
              button
              component={NavLink}
              to={ROUTES.MEME_STRATEGY_DOCS_PATH}
              className={getActiveClass(ROUTES.MEME_STRATEGY_DOCS_PATH)}
            >
              <ListItemText primary="Markdown" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to={ROUTES.MEME_STRATEGY_WALLETS}
              className={getActiveClass(ROUTES.MEME_STRATEGY_WALLETS)}
            >
              <ListItemText primary="Wallets" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to={ROUTES.MEME_STRATEGY_SETTINGS}
              className={getActiveClass(ROUTES.MEME_STRATEGY_SETTINGS)}
            >
              <ListItemText primary="Settings" />
            </ListItem>

            <ListItem
              button
              component={NavLink}
              to={ROUTES.MEME_STRATEGY_TOKEN_POOL_LAKE_PATH}
              className={getActiveClass(ROUTES.MEME_STRATEGY_TOKEN_POOL_LAKE_PATH)}
            >
              <ListItemText primary="Token pool lake" />
            </ListItem>

            <ListItem
              button
              component={NavLink}
              to={ROUTES.MEME_STRATEGY_WALLETS_HISTORY_PATH}
              className={getActiveClass(ROUTES.MEME_STRATEGY_WALLETS_HISTORY_PATH)}
            >
              <ListItemText primary="Asset history" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to={ROUTES.MEME_STRATEGY_WALLETS_PNL_PATH}
              className={getActiveClass(ROUTES.MEME_STRATEGY_WALLETS_PNL_PATH)}
            >
              <ListItemText primary="Asset PnL" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to={ROUTES.MEME_STRATEGY_BACKTEST_LIST}
              className={getActiveClass(ROUTES.MEME_STRATEGY_BACKTEST_LIST)}
            >
              <ListItemText primary="🔍 Backtest list" />
            </ListItem>
          </div>
        )}
      </ListItem>

      <ListItem className={classes.expandable}>
        <ListItem button component={NavLink} to={ROUTES.CRYPTOFEED_INTRO_PATH} onClick={() => setSubmenu('cryptofeed')}>
          <ListItemIcon>
            <AccessTimeIcon />
          </ListItemIcon>
          <ListItemText primary="CryptoFeed" />
        </ListItem>
        {submenu === 'cryptofeed' && (
          <div className={classes.submenu}>
            <ListItem
              button
              component={NavLink}
              to={ROUTES.CRYPTOFEED_INTRO_PATH}
              className={getActiveClass(ROUTES.CRYPTOFEED_INTRO_PATH)}
            >
              <ListItemText primary="Intro" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to={ROUTES.CRYPTOFEED_LIQUIDATIONS_PATH}
              className={getActiveClass(ROUTES.CRYPTOFEED_LIQUIDATIONS_PATH)}
            >
              <ListItemText primary="Liquidations" />
            </ListItem>
          </div>
        )}
      </ListItem>

      <ListItem className={classes.expandable}>
        <ListItem button component={NavLink} to={ROUTES.MEV_INTRO_PATH} onClick={() => setSubmenu('mev')}>
          <ListItemIcon>
            <RadioButtonCheckedIcon />
          </ListItemIcon>
          <ListItemText primary="MEV" />
        </ListItem>
        {submenu === 'mev' && (
          <div className={classes.submenu}>
            <ListItem
              button
              component={NavLink}
              to={ROUTES.MEV_INTRO_PATH}
              className={getActiveClass(ROUTES.MEV_INTRO_PATH)}
            >
              <ListItemText primary="Intro" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to={ROUTES.MEV_INTRO_PATH}
              className={getActiveClass(ROUTES.MEV_INTRO_PATH)}
            >
              <ListItemText primary="Swap history" />
            </ListItem>
          </div>
        )}
      </ListItem>

      <ListItem className={classes.expandable}>
        <ListItem button component={NavLink} to={ROUTES.ADMINS_PATH} onClick={() => setSubmenu('admins')}>
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <ListItemText primary="Admins & Roles" />
        </ListItem>
        {submenu === 'admins' && (
          <div className={classes.submenu}>
            <ListItem button component={NavLink} to={ROUTES.ADMINS_PATH} className={getActiveClass(ROUTES.ADMINS_PATH)}>
              <ListItemText primary="Admins" />
            </ListItem>
            <ListItem button component={NavLink} to={ROUTES.ROLES_PATH} className={getActiveClass(ROUTES.ROLES_PATH)}>
              <ListItemText primary="Roles" />
            </ListItem>
          </div>
        )}
      </ListItem>
    </List>
  );
};

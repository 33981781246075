import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import { useFormStyles } from '@bo/hooks';
import { Button } from '@material-ui/core';
import { SettingItemUpdateRequest, SettingListItem } from '@bo/types';
import { JsonEditor } from 'json-edit-react';

interface IProps {
  onSave: (data: SettingItemUpdateRequest) => void;
  saving: boolean;
  item: SettingListItem;
}

export function UpdateSettingConfig({ onSave, saving, item }: IProps) {
  const formClasses = useFormStyles();
  const [newData, setNewData] = useState(item.data);

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: 24 }}>
        <Typography variant="h6" color="primary">
          Update settings
        </Typography>
      </Grid>

      <Formik
        initialValues={{ id: item.id, data: item.data } as SettingItemUpdateRequest}
        onSubmit={() => {
          const data = { id: item.id, data: newData } as SettingItemUpdateRequest;
          onSave(data);
        }}
      >
        {({ isValid }) => {
          return (
            <Form className={formClasses.root}>
              <div className={formClasses.actions}>
                <Button type="submit" disabled={saving || !isValid} variant="contained" size="large">
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>

      <JsonEditor
        data={item.data}
        showErrorMessages={true}
        theme={['candyWrapper']}
        setData={(json) => setNewData(json)}
        maxWidth={900}
      />
    </>
  );
}

import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { DetailsPopup, TabsPanel, BoolLabel } from '@bo/components';
import AdminOverviewTab from './Overview';
import { Admin } from '@bo/types';
import { useAdminDetails, useRolesList } from '@bo/api';

interface IProps {
  id: string;
  onClose: () => void;
  onCreate: (data: Admin) => void;
  onUpdate: (data: Admin) => void;
}

export function AdminDetails({ onClose, id, onCreate, onUpdate }: IProps) {
  const view = id !== 'new';
  const [{ data: admin }, loading, load, update] = useAdminDetails();
  const [{ data: roles }, rolesLoading, loadRoles] = useRolesList();

  useEffect(() => {
    if (view) {
      load({ id });
    }
  }, [id]);

  useEffect(() => {
    loadRoles();
  }, []);

  const tabs = [
    {
      title: 'Overview',
      content: (
        <AdminOverviewTab
          admin={admin}
          onCreate={onCreate}
          view={view}
          roles={roles}
          onUpdate={(data) => {
            update(data);
            onUpdate(data);
          }}
        />
      ),
    },
  ];

  if (!admin) {
    <Typography align="center">No data to display</Typography>;
  }

  return (
    <DetailsPopup loading={loading || rolesLoading} onClose={onClose}>
      <>
        <Typography variant="h6" color="primary">
          {id === 'new' && 'Create admin'}
          {id !== 'new' && (
            <>
              {admin.email}&nbsp;&nbsp;
              <BoolLabel value={admin.enabled} size="small" labels={['Active', 'Inactive']} />
            </>
          )}
        </Typography>
        <TabsPanel tabs={tabs} />
      </>
    </DetailsPopup>
  );
}

import React, { useEffect } from 'react';
import { DetailsPopup } from '@bo/components';
import { SettingListItem } from '@bo/types';
import { UpdateSettingConfig } from './UpdateSettings';
import { useSettingsUpdate } from '@bo/api';

interface IProps {
  method: string;
  item: SettingListItem;
  onSave: () => void;
  onClose: () => void;
}

export function SettingPopup({ onSave, onClose, item, method }: IProps) {
  const updateCb = () => onSave();
  const [update, updating] = useSettingsUpdate(updateCb);

  useEffect(() => {
    if (!['update'].includes(method)) return;
  }, [method]);

  return (
    <DetailsPopup width={method === 'update' ? 1000 : 600} onClose={onClose}>
      {method === 'update' ? <UpdateSettingConfig onSave={update} saving={updating} item={item} /> : null}
    </DetailsPopup>
  );
}

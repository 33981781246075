import { useUsersList } from '@bo/api';
import { EmptyList, Loader } from '@bo/components';
import { ROUTES, DEFAULT_LIST_LIMIT as limit } from '@bo/constants';
import { IColumn, useColumns, useGlobalStyles } from '@bo/hooks';
import { UserScope } from '@bo/types';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Waypoint } from 'react-waypoint';
import { UserDetails } from './Details';
import { UsersList } from './UsersList';

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'pKey', name: 'Public Key', active: true },
  { id: 'createdAt', name: 'Registered At', active: true },
];

export function UsersPage() {
  const classes = useGlobalStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [columns, ColumnsFilter] = useColumns('blockchains', defaultColumns);
  const [{ data: items, error, cursor, left }, loading, load] = useUsersList();

  function setDetails(id?: string) {
    navigate(`${ROUTES.CUSTOMERS_PATH}${id ? `/${id}` : ''}`);
  }

  useEffect(() => {
    load({ scope: UserScope.CONSUMER, limit });
  }, []);

  if (items.length === 0) {
    return <EmptyList error={error} loading={loading} />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justifyContent="space-between">
              <Grid item />
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          <>
            <UsersList items={items} onRowClick={setDetails} columns={columns} />
            {left && cursor && <Waypoint onEnter={() => load({ scope: UserScope.CONSUMER, cursor, limit })} />}
          </>
          <Loader loading={loading} />
        </Paper>
      </Grid>
      {id && <UserDetails id={id} onClose={() => setDetails()} />}
    </Grid>
  );
}

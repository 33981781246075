import React from 'react';
import { IColumn } from '@bo/hooks';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { TableHeader } from './TableHeader';

export type TableListContentType<T> = {
  [key: string]: (item: T) => React.ReactElement | string | number | null | undefined;
};

interface ITableListProps<T> {
  items: T[];
  columns: IColumn[];
  content?: TableListContentType<T>;
  onRowClick?(i: T): void;
}

export function TableList<T>({ items, columns, content, onRowClick }: ITableListProps<T>) {
  const defaultContent = (item: T, column: keyof T) => item[column];
  const isClickable = Boolean(onRowClick);
  return (
    <Table size="small">
      <TableHeader columns={columns} />
      <TableBody>
        {items.map((i, index) => (
          <TableRow
            key={index}
            onClick={() => onRowClick?.(i)}
            hover={isClickable}
            style={{ cursor: isClickable ? 'pointer' : 'default' }}
          >
            {columns.map((c, index) => (
              <TableCell {...c.cellProps} key={index}>
                <>{content?.[c.id]?.(i) || defaultContent(i, c.id as keyof T)}</>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

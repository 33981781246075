import { ROUTES } from '@bo/constants';

export const pageTitle: { [key: string]: string } = {
  [ROUTES.ADMINS_PATH]: 'Admins',
  [ROUTES.CUSTOMERS_PATH]: 'Customers',
};

export function getPageTitle(path: string): string {
  let title = '';
  for (const p of Object.keys(pageTitle)) {
    if (path.includes(p)) {
      title = pageTitle[p];
      break;
    }
  }
  return title;
}

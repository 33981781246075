/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useChainList, useSettingList, useTplList, useTplStatusList, useWalletList } from '@bo/api';
import { IColumn, useGlobalStyles } from '@bo/hooks';
import { SettingListItem, TplListItem, TplListRequest, WalletListItem } from '@bo/types';
import { TableList, TableListContentType, BoolLabel, DateView, EmptyList } from '@bo/components';
import { SearchForm } from './SearchForm';
import { SettingPopup } from './details/popup';
import { Button, TableCell } from '@material-ui/core';

const columns: IColumn[] = [
  { id: 'id', name: 'id', active: true },
  // { id: 'data', name: 'data', active: true },
  { id: 'created_at', name: 'created at', active: true, cellProps: { align: 'center' } },
  { id: 'updated_at', name: 'updated at', active: true, cellProps: { align: 'center' } },
  { id: 'actions', name: 'actions', active: true, cellProps: { align: 'center' } },
];

export const SettingsPage = () => {
  const classes = useGlobalStyles();
  const [{ data: items, error, cursor, left }, loading, load] = useSettingList();

  const [filter, setFilter] = useState<TplListRequest>({ chainList: [], statusList: [] });
  const [item, setItem] = useState({} as SettingListItem);

  function setDetails(data?: SettingListItem) {
    if (data) {
      setItem(data);
    } else {
      setItem({} as SettingListItem);
      load({ ...filter });
    }
  }

  useEffect(() => {
    load({ ...filter });
  }, [filter]);

  const handleRowClick = () => {};

  const content: TableListContentType<SettingListItem> = {
    id: (i) => i.id,
    created_at: (i) => <DateView value={i.created_at} />,
    updated_at: (i) => <DateView value={i.updated_at} />,
    actions: (i) => (
      <TableCell key={'actions'}>
        <Button
          variant="contained"
          size="small"
          onClick={() => setDetails(i)}
          style={{ marginRight: '10px', height: '17px' }}
        >
          update
        </Button>
      </TableCell>
    ),
  };

  if (items.length === 0)
    return (
      <>
        <SearchForm initialValues={filter} setFilter={setFilter} />
        <EmptyList error={error} loading={loading} />
      </>
    );

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <SearchForm initialValues={filter} setFilter={setFilter} />
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justifyContent="space-between">
              <Grid item></Grid>
            </Grid>
          </CardActions>
          <TableList<SettingListItem> items={items} columns={columns} content={content} onRowClick={handleRowClick} />
        </Paper>
      </Grid>

      {Object.keys(item).length > 0 && (
        <SettingPopup method={'update'} item={item} onSave={() => setDetails()} onClose={() => setDetails()} />
      )}
    </Grid>
  );
};

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { SelectField } from '@bo/components';
import { BacktestItemUpdateRequest, BacktestListItem } from '@bo/types';
import { Form, Formik } from 'formik';
import { formikValidator, required } from '@bo/validators';
import { useFormStyles } from '@bo/hooks';
import { Button } from '@material-ui/core';
import { backtestStatusList } from '../BacktestPage';

interface IProps {
  onSave: (data: BacktestItemUpdateRequest) => void;
  saving: boolean;
  item: BacktestListItem;
}

export function BacktestUpdate({ onSave, saving, item }: IProps) {
  const formClasses = useFormStyles();
  const initialValues = { id: item.id, status: item.status } as BacktestItemUpdateRequest;

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: 24 }}>
        <Typography variant="h6" color="primary">
          Update backtest {item.id}
        </Typography>
      </Grid>

      <Formik
        initialValues={initialValues}
        validate={(values) => {
          return formikValidator({ id: required(values.id), status: required(values.status) });
        }}
        onSubmit={(values) => {
          const data = { ...values } as BacktestItemUpdateRequest;
          onSave(data);
        }}
      >
        {({ isValid }) => {
          return (
            <Form className={formClasses.root}>
              <div className={formClasses.row}>
                <SelectField
                  name="status"
                  label="status"
                  options={backtestStatusList.map((v) => ({ value: v, label: v }))}
                  noEmptyOption
                />
              </div>

              <div className={formClasses.actions}>
                <Button type="submit" disabled={saving || !isValid} variant="contained" size="large">
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export const formatPnL = (value: number, isPercent = false) =>
  !value ? '' : value > 0 ? `+${value}${isPercent ? '%' : ''}` : `${value}${isPercent ? '%' : ''}`;

export enum CHAIN_LIST {
  ETHEREUM = 'ETHEREUM',
  BASE = 'BASE',
  POLYGON = 'POLYGON',
}

export const CHAIN_DEXSCREEN_MAP: any = {
  ETHEREUM: 'ethereum',
  BASE: 'base',
  POLYGON: 'polygon',
};

export const getDexscreenerLink = (chain: string, token: string) => {
  return `https://dexscreener.com/${CHAIN_DEXSCREEN_MAP[chain]}/${token}`;
};

import { FC } from 'react';
import { Form, Formik } from 'formik';
import { SelectField } from '@bo/components';
import { Button } from '@material-ui/core';
import { useFormStyles, useGlobalStyles } from '@bo/hooks';
import { TplListRequest } from '@bo/types';

interface ITplSearchFormProps {
  setFilter: (filter: TplListRequest) => void;
  initialValues: TplListRequest;
  statusList: any[];
  chainList: any[];
}

export const SearchForm: FC<ITplSearchFormProps> = ({ statusList, chainList, initialValues, setFilter }) => {
  const formClasses = useFormStyles();
  const classes = useGlobalStyles();

  return (
    <div className={classes.searchForm}>
      <Formik
        onSubmit={(values: TplListRequest) => {
          setFilter({ ...values });
        }}
        initialValues={initialValues}
      >
        {() => (
          <Form>
            <div className={formClasses.row}>
              <SelectField
                name="statusList"
                label="status list"
                options={statusList.map((v) => ({ value: v, label: v }))}
                fullWidth
                multi
                noEmptyOption
                margin="dense"
              />
              <SelectField
                name="chainList"
                label="chain list"
                options={chainList.map((v) => ({ value: v, label: v }))}
                fullWidth
                multi
                noEmptyOption
                margin="dense"
              />
              <div style={{ marginTop: '8px', textAlign: 'right', maxWidth: '100px' }}>
                <Button type="submit" variant="contained" size="large">
                  Apply
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

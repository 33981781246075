import makeStyles from '@material-ui/core/styles/makeStyles';

export const useHealthCheckStyles = makeStyles((theme) => ({
  label: {
    width: 150,
    flex: `0 0 150px`,
    marginRight: theme.spacing(2),
    fontWeight: 500,
  },
  row: {
    display: 'flex',
    marginBottom: '10px',
    alignItems: 'center',
  },
  actions: {
    marginTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

import { ROUTES } from '@bo/constants';
import { MainLayout } from '@bo/layouts';
import { AdminsPage } from '@bo/views/admin/Admins';
import { RolesPage } from '@bo/views/admin/Roles';
import { UsersPage } from '@bo/views/admin/Users';
import { Navigate, RouteObject } from 'react-router-dom';
import { App } from './App';
import { TokenPoolPage } from './views/meme_strategy/TokenPoolLake';
import { WalletHistoryPage } from './views/meme_strategy/WalletHistory';
import { WalletPage } from './views/meme_strategy/Wallet';
import { SettingsPage } from './views/meme_strategy/Settings';
import { DocsPage } from './views/meme_strategy/Docs/DocsPage';
import { BacktestPage } from './views/meme_strategy/backtests/Backtests';
import { AssetPage } from './views/meme_strategy/backtests/Assets';
import { AssetLogPage } from './views/meme_strategy/backtests/AssetLogs';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <MainLayout />,
        children: [
          { path: '/', element: <Navigate to={ROUTES.ADMINS_PATH} /> },
          { path: ROUTES.ADMINS_PATH, element: <AdminsPage /> },
          { path: ROUTES.ADMINS_PATH + '/:adminId', element: <AdminsPage /> },
          { path: ROUTES.ROLES_PATH, element: <RolesPage /> },
          { path: ROUTES.ROLES_PATH + '/:roleId', element: <RolesPage /> },
          { path: ROUTES.CUSTOMERS_PATH, element: <UsersPage /> },
          { path: ROUTES.CUSTOMERS_PATH + '/:id', element: <UsersPage /> },

          { path: ROUTES.MEME_STRATEGY_TOKEN_POOL_LAKE_PATH, element: <TokenPoolPage /> },
          { path: ROUTES.MEME_STRATEGY_DOCS_PATH, element: <DocsPage /> },
          { path: ROUTES.MEME_STRATEGY_WALLETS_HISTORY_PATH, element: <WalletHistoryPage /> },
          { path: ROUTES.MEME_STRATEGY_WALLETS_PNL_PATH, element: <UsersPage /> },
          { path: ROUTES.MEME_STRATEGY_WALLETS, element: <WalletPage /> },
          { path: ROUTES.MEME_STRATEGY_SETTINGS, element: <SettingsPage /> },

          { path: ROUTES.MEME_STRATEGY_BACKTEST_LIST, element: <BacktestPage /> },
          { path: ROUTES.MEME_STRATEGY_BACKTEST_ASSET_LIST + '/:backtestId/assets', element: <AssetPage /> },
          { path: ROUTES.MEME_STRATEGY_BACKTEST_ASSET_ONE + '/:backtestId/assets/:assetId', element: <AssetLogPage /> },

          { path: ROUTES.CRYPTOFEED_INTRO_PATH, element: <UsersPage /> },
          { path: ROUTES.CRYPTOFEED_LIQUIDATIONS_PATH, element: <UsersPage /> },

          { path: ROUTES.MEV_INTRO_PATH, element: <UsersPage /> },
        ],
      },
    ],
  },
];

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { FormHelperText } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { Formik } from 'formik';
import { IColumn } from '@bo/hooks';
import { CheckboxField } from '@bo/components/Form';

interface IProps {
  columns: IColumn[];
  onChange: any;
  maxColumnsSelected?: number;
}

const useStyles = makeStyles(() => ({
  error: {
    fontSize: 14,
  },
}));

export function ColumnsFilter({ columns, onChange, maxColumnsSelected = 0 }: IProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [error, setToggleError] = useState(false);
  let data: IColumn[][] = [];
  const colsCount = columns.length > 9 ? Math.ceil(columns.length / 5) : 1;
  if (colsCount > 1) {
    let k = 0;
    while (k < colsCount) {
      const start = k * 5;
      data.push(columns.slice(start, start + 5));
      k++;
    }
  } else {
    data = [columns];
  }

  const onSubmit = (values: any) => {
    const qtyActiveColumns = Object.values(values).filter((columnStatus: any) => columnStatus).length;

    if (!!maxColumnsSelected && qtyActiveColumns > maxColumnsSelected) {
      setToggleError(true);
    } else {
      onChange(values);
      setOpen(false);
      setToggleError(false);
    }
  };

  const initVals: { [key: string]: boolean } = {};
  columns.forEach((c) => {
    initVals[c.id] = c.active;
  });

  return (
    <>
      <Button variant="contained" size="medium" onClick={() => setOpen(true)} startIcon={<SettingsIcon />}>
        Columns
      </Button>
      {open && (
        <Dialog open onClose={() => setOpen(false)}>
          <Paper>
            <DialogTitle>Columns settings</DialogTitle>
            <Formik onSubmit={onSubmit} initialValues={initVals}>
              {({ values, handleChange, handleSubmit }) => {
                const disabled = Object.values(values).filter((v: any) => !!v).length === 1;
                return (
                  <form onSubmit={handleSubmit}>
                    <DialogContent>
                      <Grid container spacing={2}>
                        {data.map((cols, i) => (
                          <Grid item key={i}>
                            {cols.map((c) => (
                              <div key={c.id}>
                                <CheckboxField
                                  name={c.id}
                                  label={c.name || c.id}
                                  disabled={disabled && values[c.id]}
                                  onChange={handleChange}
                                  checked={values[c.id]}
                                />
                              </div>
                            ))}
                          </Grid>
                        ))}
                      </Grid>
                    </DialogContent>
                    <DialogContent>
                      {error && (
                        <FormHelperText
                          error
                          className={classes.error}
                        >{`You can select only ${maxColumnsSelected} columns`}</FormHelperText>
                      )}
                    </DialogContent>
                    <DialogActions style={{ padding: '16px 24px' }}>
                      <Grid container spacing={2} justifyContent="space-between">
                        <Grid item>
                          <Button variant="contained" size="medium" type="submit">
                            Apply
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="text" size="medium" color="default" onClick={() => setOpen(false)}>
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </DialogActions>
                  </form>
                );
              }}
            </Formik>
          </Paper>
        </Dialog>
      )}
    </>
  );
}

import React, { FC } from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { IColumn } from '@bo/hooks';

interface ITableHeaderProps {
  columns: IColumn[];
}

export const TableHeader: FC<ITableHeaderProps> = ({ columns }) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map(
          (c) =>
            c.component || (
              <TableCell key={c.id} {...c.cellProps}>
                {c.name}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;

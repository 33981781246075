/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useWalletList } from '@bo/api';
import { IColumn, useGlobalStyles } from '@bo/hooks';
import { TplListItem, TplListRequest, WalletListItem } from '@bo/types';
import { TableList, TableListContentType, BoolLabel, DateView, EmptyList } from '@bo/components';
import { SearchForm } from './SearchForm';
import { Button, TableCell } from '@material-ui/core';
import { WalletPopup } from './details/popup';

const columns: IColumn[] = [
  { id: 'id', name: 'id', active: true },
  { id: 'address', name: 'address', active: true },
  { id: 'auto_buy', name: 'auto_buy', active: true },
  { id: 'auto_sell', name: 'auto_sell', active: true, cellProps: { align: 'center' } },
  { id: 'is_deprecated', name: 'deprecated', active: true, cellProps: { align: 'center' } },
  { id: 'chain', name: 'chain', active: true },
  { id: 'created_at', name: 'created at', active: true, cellProps: { align: 'center' } },
  { id: 'updated_at', name: 'updated at', active: true, cellProps: { align: 'center' } },
  { id: 'actions', name: 'actions', active: true, cellProps: { align: 'center' } },
];

export const WalletPage = () => {
  const classes = useGlobalStyles();
  const [{ data: items, error, cursor, left }, loading, load] = useWalletList();

  const [filter, setFilter] = useState<TplListRequest>({ chainList: [], statusList: [] });
  const [walletData, setWalletData] = useState({} as { wallet: WalletListItem; method: string });

  useEffect(() => {
    load({ ...filter });
  }, [filter]);

  const handleRowClick = () => {};

  function setDetails(data: { wallet?: WalletListItem; method: string }) {
    if (data.wallet) {
      // @ts-expect-error skip
      setWalletData(data);
    } else {
      setWalletData({} as { wallet: WalletListItem; method: string });
      load({ ...filter });
    }
  }

  const content: TableListContentType<WalletListItem> = {
    id: (i) => i.id,
    address: (i) => (
      <a style={{ color: 'white' }} rel="noreferrer">
        {i.address}
      </a>
    ),
    auto_buy: (i) => <BoolLabel value={i.auto_buy} />,
    auto_sell: (i) => <BoolLabel value={i.auto_sell} />,
    is_deprecated: (i) => <BoolLabel value={i.is_deprecated} />,
    chain: (i) => i.chain,
    created_at: (i) => <DateView value={i.created_at} />,
    updated_at: (i) => <DateView value={i.updated_at} />,
    actions: (i) => (
      <TableCell key={'actions'}>
        <Button
          variant="contained"
          size="small"
          onClick={() => setDetails({ wallet: i, method: 'update' })}
          style={{ marginRight: '10px', height: '17px' }}
        >
          update
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => setDetails({ wallet: i, method: 'updateConfig' })}
          style={{ marginRight: '10px', height: '17px' }}
        >
          config
        </Button>
      </TableCell>
    ),
  };

  if (items.length === 0)
    return (
      <>
        <SearchForm initialValues={filter} setFilter={setFilter} />
        <EmptyList error={error} loading={loading} />
      </>
    );

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <SearchForm initialValues={filter} setFilter={setFilter} />
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justifyContent="space-between">
              <Grid item></Grid>
            </Grid>
          </CardActions>
          <TableList<WalletListItem> items={items} columns={columns} content={content} onRowClick={handleRowClick} />
        </Paper>
      </Grid>

      {Object.keys(walletData).length > 0 && (
        <WalletPopup
          method={walletData.method}
          item={walletData.wallet}
          // @ts-expect-error skip
          onSave={() => setDetails({})}
          // @ts-expect-error skip
          onClose={() => setDetails({})}
        />
      )}
    </Grid>
  );
};

import { Api } from '@bo/api';
import { useFetch, useSend } from '@bo/hooks';
import { Admin, AdminListRequest, AdminSaveRequest, ApiOneRequest } from '@bo/types';
import { apiPrefix } from '@bo/constants';

export function useAdminsList() {
  const data: Admin[] = [];
  return useFetch<AdminListRequest, Admin[], Admin>(
    async (data) => Api.post(`${apiPrefix.backoffice}/admins/list`, data),
    data
  );
}

export function useSaveAdmin(cb: (data: Admin) => void) {
  return useSend<AdminSaveRequest, Admin>(
    async (data) => Api.post(`${apiPrefix.backoffice}/admins/add`, data),
    cb,
    `Admin is created`,
    undefined,
    {
      UQ_admin_email: 'Email is already registered in the system',
    }
  );
}
export function useUpdateAdmin(cb: (data: Admin) => void) {
  return useSend<AdminSaveRequest, Admin>(
    async (data) => Api.post(`${apiPrefix.backoffice}/admins/update`, data),
    cb,
    `Admin is updated`
  );
}

export function useAdminDetails() {
  const data = {} as Admin;
  return useFetch<ApiOneRequest, Admin>(async (data) => Api.post(`${apiPrefix.backoffice}/admins/one`, data), data);
}

import React, { useEffect } from 'react';
import { DetailsPopup } from '@bo/components';
import { useWalletUpdate } from '@bo/api/meme_strategy';
import { UpdateWalletPopup } from './UpdateWallet';
import { WalletListItem } from '@bo/types';
import { UpdateWalletConfig } from './UpdateWalletConfig';

interface IProps {
  method: string;
  item: WalletListItem;
  onSave: () => void;
  onClose: () => void;
}

export function WalletPopup({ onSave, onClose, item, method }: IProps) {
  const updateConfigCb = () => onSave();
  const [updateConfig, updatingConfig] = useWalletUpdate(updateConfigCb);

  const updateCb = () => onSave();
  const [update, updating] = useWalletUpdate(updateCb);

  useEffect(() => {
    if (!['update', 'updateConfig'].includes(method)) return;
  }, [method]);

  return (
    <DetailsPopup width={method === 'updateConfig' ? 1000 : 600} onClose={onClose}>
      {method === 'update' ? <UpdateWalletPopup onSave={update} saving={updating} item={item} /> : null}
      {method === 'updateConfig' ? (
        <UpdateWalletConfig onSave={updateConfig} saving={updatingConfig} item={item} />
      ) : null}
    </DetailsPopup>
  );
}

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { TextCopy } from '@bo/components';

interface IProps {
  label: string;
  children: any;
  copy?: string;
  labelWidth?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    alignItems: 'baseline',
  },
  label: {
    width: 150,
    flex: `0 0 150px`,
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flex: '1 1 0px',
    alignItems: 'center',
  },
}));

export function DataRow({ label, children, copy, labelWidth }: IProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography
        className={classes.label}
        variant="body2"
        color="textSecondary"
        component="div"
        style={{ flexBasis: labelWidth }}
      >
        <>{label}</>
        {copy && copy.length && <TextCopy text={copy} />}
      </Typography>
      <div className={classes.content}>{children}</div>
    </div>
  );
}

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { ApiError } from '@bo/types';

interface IStylesProps {
  width?: number;
}

const useStyles = makeStyles((theme) => ({
  root: ({ width }: IStylesProps) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
    minWidth: width ? `${width}px` : 'calc(100vw - 320px)',
  }),
}));

interface IEmptyListProps {
  error?: ApiError | string;
  loading: boolean;
  text?: string;
  width?: number;
}

export function EmptyList({ error, loading, text, width }: IEmptyListProps) {
  const classes = useStyles({ width });

  if (loading) return null;

  if (error) {
    return (
      <Typography variant="body1" color="secondary">
        {typeof error === 'string' ? error : error.message || error.error}
      </Typography>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="body1" color="textPrimary">
        {text || 'There are no elements here...'}
      </Typography>
    </div>
  );
}

/* eslint-disable react/no-string-refs */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useBacktestAssetLogChart, useBacktestAssetLogList } from '@bo/api';
import { IColumn, useColumns, useGlobalStyles } from '@bo/hooks';
import { BacktesAssetLogtListRequest, BacktestAssetLogListItem } from '@bo/types';
import { TableList, TableListContentType, DateView, Loader } from '@bo/components';
import { Waypoint } from 'react-waypoint';
import { useNavigate, useParams } from 'react-router';
// import { TypeChooser } from 'react-stockcharts/lib/helper';
// import CandleStickChartWithHoverTooltip from './chart/Chart';
import { timeParse } from 'd3-time-format';

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'id', active: true },
  { id: 'chain', name: 'chain', active: true },
  { id: 'block', name: 'block', active: true },
  { id: 'swap_type', name: 'swap type', active: true },
  { id: 'token_amount', name: 'token amount', active: true },
  { id: 'eth_amount', name: 'eth amount', active: true },
  { id: 'native_price', name: 'native price', active: true },
  { id: 'timestamp', name: 'timestamp', active: true, cellProps: { align: 'center' } },
];

type BacktestState = { backtest: BacktestAssetLogListItem; method: string };

export const AssetLogPage = () => {
  const classes = useGlobalStyles();
  const [columns, ColumnsFilter] = useColumns('backtests', defaultColumns);
  const [{ data: items, error, cursor, left }, loading, load] = useBacktestAssetLogList();
  const [{ data: chartData }, loadingChart, loadChart] = useBacktestAssetLogChart();
  const navigate = useNavigate();
  const { backtestId, assetId } = useParams();

  // @ts-expect-error skip
  const [filter, setFilter] = useState<BacktesAssetLogtListRequest>({ backtestId });
  const [backtestData, setBacktestData] = useState({} as BacktestState);

  useEffect(() => {
    // @ts-expect-error skip
    load({ backtestId, assetId });
    // @ts-expect-error skip
    loadChart({ backtestId, assetId });
  }, [filter]);

  const handleRowClick = () => {};

  function setDetails(data: BacktestState) {
    if (data.backtest) {
      setBacktestData(data);
    }
  }

  const parseDate = timeParse('%Y-%m-%d');

  const content: TableListContentType<BacktestAssetLogListItem> = {
    id: (i) => i.id,
    chain: (i) => i.chain,
    block: (i) => <div>{i.block}</div>,
    swap_type: (i) => i.swap_type,
    token_amount: (i) => i.token_amount,
    eth_amount: (i) => i.eth_amount,
    native_price: (i) => i.native_price,
    timestamp: (i) => <DateView value={i.timestamp} />,
  };

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        {/* <SearchForm initialValues={filter} setFilter={setFilter} /> */}
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justifyContent="space-between">
              <Grid item></Grid>
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          <>
            <TableList<BacktestAssetLogListItem>
              items={items}
              columns={columns}
              content={content}
              onRowClick={handleRowClick}
            />
            {/* <CardIssueCurrencyList items={items} onRowClick={setDetails} columns={columns} /> */}
            {left && cursor && (
              <Waypoint
                onEnter={() => {
                  // @ts-expect-error skip
                  load({ backtestId, assetId, cursor });
                }}
              />
            )}
          </>
          <Loader loading={loading} />
        </Paper>
        <div style={{ backgroundColor: '#ffffff' }}>
          {/* {chartData && chartData?.chartList?.length > 0 ? (
            <TypeChooser>
              {(type: any) => (
                <CandleStickChartWithHoverTooltip
                  data={chartData.chartList.map((el) => ({ ...el, date: parseDate(el.date) }))}
                  type={type}
                  logList={chartData.logList}
                />
              )}
            </TypeChooser>
          ) : null} */}
        </div>
      </Grid>
    </Grid>
  );
};

/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useChainList, useWalletHistoryList, useWalletList } from '@bo/api';
import { IColumn, useGlobalStyles } from '@bo/hooks';
import { WalletHistoryListItem, WalletHistoryListRequest } from '@bo/types';
import { TableList, TableListContentType, DateView, EmptyList } from '@bo/components';
import { SearchForm } from './SearchForm';
import { ethers } from 'ethers';

const columns: IColumn[] = [
  { id: 'swap_type', name: 'swap_type', active: true },
  { id: 'swap_price', name: 'swap_price', active: true },
  { id: 'native_price', name: 'native_price', active: true },
  { id: 'swap_amount', name: 'swap_amount', active: true },
  { id: 'decimals', name: 'decimals', active: true },
  { id: 'name', name: 'name', active: true },
  { id: 'strategy', name: 'strategy', active: true },
  { id: 'chain', name: 'chain', active: true, cellProps: { align: 'center' } },
  { id: 'created_at', name: 'created at', active: true, cellProps: { align: 'center' } },
];

const dexScreenMap = {
  ETHEREUM: 'https://dexscreener.com/ethereum',
  BASE: 'https://dexscreener.com/base',
};

export const WalletHistoryPage = () => {
  const classes = useGlobalStyles();
  const [{ data: items, error, cursor, left }, loading, load] = useWalletHistoryList();
  const [{ data: walletList }, , loadWalletList] = useWalletList();
  const [{ data: chainList }, , loadChainList] = useChainList();

  const [filter, setFilter] = useState<WalletHistoryListRequest>({ chainList: [], walletList: [] });

  useEffect(() => {
    load({ ...filter });
  }, [filter]);

  useEffect(() => {
    loadChainList();
    loadWalletList();
  }, []);

  const handleRowClick = () => {};

  const content: TableListContentType<WalletHistoryListItem> = {
    swap_type: (i) => i.swap_type,
    swap_price: (i) => i.swap_price,
    native_price: (i) => i.native_price,
    swap_amount: (i) => Number(ethers.utils.formatUnits(i.swap_amount, i.decimals)).toFixed(6),
    decimals: (i) => i.decimals,
    name: (i) => (
      <a
        style={{ color: 'white' }}
        target="_blank"
        // @ts-expect-error skip
        href={`${dexScreenMap[i.chain]}/${i.token}?maker=${i.wallet}`}
        rel="noreferrer"
      >
        {i.name}
      </a>
    ),
    strategy: (i) => i.strategy,
    chain: (i) => i.chain,
    created_at: (i) => <DateView value={i.created_at} />,
  };

  if (items.length === 0 && chainList.length > 0 && walletList.length > 0)
    return (
      <>
        <SearchForm chainList={chainList} walletList={walletList} initialValues={filter} setFilter={setFilter} />
        <EmptyList error={error} loading={loading} />
      </>
    );

  return chainList.length > 0 && walletList.length > 0 ? (
    <Grid container spacing={3}>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <SearchForm chainList={chainList} walletList={walletList} initialValues={filter} setFilter={setFilter} />
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justifyContent="space-between">
              <Grid item></Grid>
            </Grid>
          </CardActions>
          <TableList<WalletHistoryListItem>
            items={items}
            columns={columns}
            content={content}
            onRowClick={handleRowClick}
          />
        </Paper>
      </Grid>
    </Grid>
  ) : null;
};

import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { BoolLabel, DateView } from '@bo/components';
import { AdminRole } from '@bo/types';
import { IColumn, useGlobalStyles } from '@bo/hooks';

interface IProps {
  items: AdminRole[];
  columns: IColumn[];
  onRowClick: any;
}

const content: { [key: string]: (i: AdminRole, key: number) => any } = {
  id: (i, key) => <TableCell key={key}>{i.id}</TableCell>,
  name: (i, key) => <TableCell key={key}>{i.name}</TableCell>,
  base: (i, key) => (
    <TableCell key={key}>
      <BoolLabel value={i.baseRole} />
    </TableCell>
  ),
  created: (i, key) => (
    <TableCell key={key}>
      <DateView value={i.createdAt} />
    </TableCell>
  ),
};

export function RolesList({ items, onRowClick, columns }: IProps) {
  const classes = useGlobalStyles();

  return (
    <Table size="small">
      <TableHead>
        <TableRow>{columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}</TableRow>
      </TableHead>
      <TableBody>
        <>
          {items.map((i) => (
            <TableRow key={i.id} className={classes.pointer} onClick={() => onRowClick(i.id)} hover>
              <>{columns.map((c, index) => content[c.id](i, index))}</>
            </TableRow>
          ))}
        </>
      </TableBody>
    </Table>
  );
}

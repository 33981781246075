export class AccountSettings {
  currencyId = '';
  favoriteTokenId = '';
  language = '';
  isTester = false;
}

export class UserListItem {
  id = '';
  publicKey = '';
  isSeedImported?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export class User extends UserListItem {
  accountSettings: AccountSettings = new AccountSettings();
}

export enum UserScope {
  CONSUMER = 'consumer',
}

export interface UsersListRequest {
  scope: UserScope;
}

export interface UpdateAccountSettingsRequest {
  accountId: string;
  settings: AccountSettings;
}

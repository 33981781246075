export const ROUTES = {
  ADMINS_PATH: '/admins/list',
  CUSTOMERS_PATH: '/customers',
  ROLES_PATH: '/admins/roles',
  MEME_STRATEGY_DOCS_PATH: '/meme_strategy/docs',
  MEME_STRATEGY_WALLETS_HISTORY_PATH: '/meme_strategy/wallets/history',
  MEME_STRATEGY_WALLETS_PNL_PATH: '/meme_strategy/wallets/pnl',
  MEME_STRATEGY_TOKEN_POOL_LAKE_PATH: '/meme_strategy/token_pool_lake/list',
  MEME_STRATEGY_BACKTEST_LIST: '/meme_strategy/backtest/list',
  MEME_STRATEGY_BACKTEST_ASSET_LIST: '/meme_strategy/backtest',
  MEME_STRATEGY_BACKTEST_ASSET_ONE: '/meme_strategy/backtest',

  CRYPTOFEED_INTRO_PATH: '/cryptofeed/intro',
  CRYPTOFEED_LIQUIDATIONS_PATH: '/cryptofeed/liquidations/list',
  MEV_INTRO_PATH: '/mev/intro',

  MEME_STRATEGY_WALLETS: '/meme_strategy/wallets',
  MEME_STRATEGY_SETTINGS: '/meme_strategy/settings',
};

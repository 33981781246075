import { ACCESS_TOKEN_EXPIRE_KEY, ACCESS_TOKEN_KEY, REFRESH_TOKEN_EXPIRE_KEY, REFRESH_TOKEN_KEY } from '@bo/constants';
import { IAuthResponse } from '@bo/types';

export const getStorageItem = (key: string) => {
  return window.localStorage.getItem(key);
};

export const setStorageItem = (key: string, value: string) => {
  return window.localStorage.setItem(key, value);
};

export const removeStorageItem = (key: string) => {
  return window.localStorage.removeItem(key);
};

export const setAllTokensToStorage = (tokens: IAuthResponse) => {
  setStorageItem(ACCESS_TOKEN_KEY, tokens.accessToken);
  setStorageItem(ACCESS_TOKEN_EXPIRE_KEY, String(tokens.accessTokenExp * 1000 + Date.now()));
  setStorageItem(REFRESH_TOKEN_KEY, tokens.refreshToken);
  setStorageItem(REFRESH_TOKEN_EXPIRE_KEY, String(tokens.refreshTokenExp * 1000 + Date.now()));
};

export const removeAllTokensFromStorage = () => {
  removeStorageItem(ACCESS_TOKEN_KEY);
  removeStorageItem(ACCESS_TOKEN_EXPIRE_KEY);
  removeStorageItem(REFRESH_TOKEN_KEY);
  removeStorageItem(REFRESH_TOKEN_EXPIRE_KEY);
};

import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { ApiPromise, ApiError, ApiRequest } from '@bo/types';
import { getErrFromResponse } from '@bo/helpers';

interface FetchData<T> {
  data: T;
  error?: ApiError;
  left?: boolean;
  cursor?: string | null;
}

export function useFetch<Req, Res, T = any>(
  remote: (requestData?: ApiRequest<Req>, ...args: any) => ApiPromise<Res>,
  initialData: Res
): [
  FetchData<Res>,
  boolean,
  (requestData?: ApiRequest<Req>, ...args: any) => void,
  (item: T, compareKey?: string, type?: 'update' | 'delete') => void
] {
  const [fetchData, setFetchData] = useState<FetchData<Res>>({ data: initialData });
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  async function fetch(request?: ApiRequest<Req>, ...args: any) {
    if (loading) return;
    setLoading(true);
    const isPagination = !!request?.cursor;
    try {
      const { data: res } = await remote(request, ...args);
      const respData = res.data;
      setFetchData({
        ...fetchData,
        ...res,
        data: <Res>(isPagination && Array.isArray(fetchData.data) ? fetchData.data.concat(respData) : respData),
      });
    } catch (e: any) {
      let err = e.toString();
      if (e.response && e.response.data && e.response.data.error) {
        err = getErrFromResponse(e);
        setFetchData({ ...fetchData, error: e.response.data.error });
      } else {
        setFetchData({ ...fetchData, error: err });
      }
      enqueueSnackbar(err, { variant: 'error' });
    }
    setLoading(false);
  }

  const updateItem = (item: T, compareKey = 'id', type: 'update' | 'delete' = 'update') => {
    if (item === undefined) return;
    const upd = { ...fetchData };
    if (Array.isArray(fetchData.data)) {
      const items = [...fetchData.data];
      if (compareKey !== null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const i = items.findIndex((it: any) => it[compareKey].toString() === item[compareKey].toString());
        if (i !== -1) {
          if (type === 'delete') {
            items.splice(i, 1);
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            items[i] = item;
          }
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          items.unshift(item);
        }
        console.log(items, i);
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      upd.data = items;
    } else if (type === 'update') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      upd.data = item;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      upd.data = {};
    }
    setFetchData(upd);
  };

  return [fetchData, loading, fetch, updateItem];
}

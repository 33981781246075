import makeStyles from '@material-ui/core/styles/makeStyles';

export const useGlobalStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(1),
  },
  table: {
    maxWidth: 'inherit',
  },
  filters: {
    'width': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'paddingBottom': '0 !important',
    'flexFlow': 'row wrap',
    'paddingLeft': theme.spacing(1.5),
    'paddingRight': theme.spacing(1.5),
    'paddingTop': theme.spacing(1),
    '& > *': {
      'marginRight': `${theme.spacing(2)}px !important`,
      'marginBottom': `${theme.spacing(2.5)}px !important`,
      'flex': '1 1 0px',
      'minWidth': '18% !important',
      'maxWidth': '18% !important',
      '&.apply': {
        minWidth: '90px !important',
        flex: '0 0',
      },
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: '2px !important',
      paddingBottom: '2px !important',
    },
  },
  tooltiped: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    fontStyle: 'italic',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  pointer: {
    cursor: 'pointer',
  },
  successText: {
    color: theme.palette.success.main,
  },
  errorText: {
    color: theme.palette.error.main,
  },
  breakWord: {
    wordBreak: 'break-all',
  },
  dialog: {
    'padding': `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    'width': 450,
    '& .MuiDialogContent-root': {
      padding: `0 ${theme.spacing(1)}px`,
    },
    '& .MuiDialogTitle-root': {
      padding: `0 ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
  },
  cardActions: {
    'display': 'flex',
    '& .import': {
      marginLeft: theme.spacing(2),
    },
  },
  total: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '5px 10px',
    borderRadius: '5px',
    marginRight: '10px',
    backgroundColor: '#e0e0e0',
  },
  totalCount: {
    display: 'flex',
    alignItems: 'center',
  },
  totalIcon: {
    marginRight: '6px',
  },
  searchForm: {
    marginBottom: '30px',
  },
  tableLetters: {
    maxWidth: 'inherit',
    width: '100%',
    minWidth: 'fit-content',
  },
}));

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

interface ConfirmDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
  children: any | any[];
  sending: boolean;
}

export function ConfirmationDialog({ onConfirm, onCancel, children, sending }: ConfirmDialogProps) {
  return (
    <Dialog open>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} size="small">
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained" size="small" color="secondary" disabled={sending}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

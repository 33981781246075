import { createTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

import { overrides } from './overrides';

export const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: blue[200],
      dark: blue[500],
      light: blue['A200'],
    },
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  overrides,
  typography: {
    fontSize: 12,
  },
});

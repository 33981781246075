import { Api } from '../admin/api';
import { useFetch, useSend } from '@bo/hooks';
import {
  BacktesAssetListRequest,
  BacktesAssetLogtListRequest,
  BacktestAssetListItem,
  BacktestAssetLogChart,
  BacktestAssetLogChartData,
  BacktestAssetLogListItem,
  BacktestItemAddRequest,
  BacktestItemUpdateRequest,
  BacktestListItem,
  BacktestListRequest,
} from '@bo/types';
import { apiPrefix } from '@bo/constants';

export function useBacktestAssetList() {
  return useFetch<BacktesAssetListRequest, BacktestAssetListItem[], BacktestAssetListItem>(
    async (data) => Api.post(`${apiPrefix.memeStrategy}/backtests/assets/list`, data),
    []
  );
}

export function useBacktestAssetLogList() {
  return useFetch<BacktesAssetLogtListRequest, BacktestAssetLogListItem[], BacktestAssetLogListItem>(
    async (data) => Api.post(`${apiPrefix.memeStrategy}/backtests/asset-logs/list`, data),
    []
  );
}

export function useBacktestAssetLogChart() {
  return useFetch<BacktesAssetLogtListRequest, BacktestAssetLogChartData, BacktestAssetLogChartData>(
    async (data) => Api.post(`${apiPrefix.memeStrategy}/backtests/asset-logs/chart`, data),
    {} as BacktestAssetLogChartData
  );
}

export function useBacktestList() {
  return useFetch<BacktestListRequest, BacktestListItem[], BacktestListItem>(
    async (data) => Api.post(`${apiPrefix.memeStrategy}/backtests/list`, data),
    []
  );
}

export function useBacktestAdd(cb: (data: BacktestListItem) => void) {
  return useSend<BacktestItemAddRequest, BacktestListItem>(
    async (data) => Api.post(`${apiPrefix.memeStrategy}/backtests/add`, data),
    cb,
    'Backtest created'
  );
}

export function useBacktestUpdate(cb: (data: BacktestListItem) => void) {
  return useSend<BacktestItemUpdateRequest, BacktestListItem>(
    async (data) => Api.post(`${apiPrefix.memeStrategy}/backtests/update`, data),
    cb,
    'Backtest updated'
  );
}

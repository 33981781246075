import { Api } from '@bo/api';
import { useSend } from '@bo/hooks';
import { IAuthRequest, IRefreshTokenRequest, IAuthResponse, EmptyRequest, IGenerate2FAuthResponse } from '@bo/types';
import { LOCAL_ENV } from '@bo/constants';
import { removeAuthTokens } from '@bo/utils/authUtils';

export function usePasswordAuth(cb: (data: IGenerate2FAuthResponse) => void) {
  return useSend<IAuthRequest, IGenerate2FAuthResponse>(
    async (data: IAuthRequest) => Api.post(`${LOCAL_ENV ? '' : '/backoffice'}/auth/password`, data),
    cb,
    undefined,
    undefined,
    {
      'The user with email': 'This Email is not registered in system yet',
    }
  );
}

export function use2FAuth(cb: (data: IAuthResponse) => void) {
  return useSend<IAuthRequest, IAuthResponse>(
    async (data) => Api.post(`${LOCAL_ENV ? '' : '/backoffice'}/auth/password2fa`, data),
    cb,
    undefined,
    undefined,
    {
      'The user with email': 'This Email is not registered in system yet',
    }
  );
}

export function useTokensRefresh(cb: (data: IAuthResponse) => void) {
  return useSend<IRefreshTokenRequest, IAuthResponse>(
    async (data: IRefreshTokenRequest) => Api.post(`${LOCAL_ENV ? '' : '/backoffice'}/auth/refresh`, data),
    cb,
    undefined,
    () => {
      removeAuthTokens();
      window.location.reload();
    }
  );
}

export function useLogout(cb: (data: any) => void) {
  return useSend<EmptyRequest, any>(async () => Api.post(`${LOCAL_ENV ? '' : '/backoffice'}/auth/logout`, {}), cb);
}

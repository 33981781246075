import { Admin } from '@bo/types';
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Waypoint } from 'react-waypoint';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { AdminsList } from './AdminsList';
import { EmptyList } from '@bo/components/EmptyList';
import { Loader } from '@bo/components/Loader';
import { AdminDetails } from './Details';
import { useGlobalStyles, IColumn, useColumns } from '@bo/hooks';
import { useAdminsList } from '@bo/api';
import { ROUTES } from '@bo/constants';

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'email', name: 'Email', active: true },
  { id: 'active', name: 'Active', active: true },
  { id: 'created', name: 'Created at', active: true },
  { id: 'updated', name: 'Updated at', active: true },
];

export function AdminsPage() {
  const classes = useGlobalStyles();
  const [{ data: items, left, cursor, error }, loading, load, updateAdmin] = useAdminsList();
  const [columns, ColumnsFilter] = useColumns('admins', defaultColumns);
  const navigate = useNavigate();
  const { adminId } = useParams();

  function setDetails(id?: string) {
    navigate(`${ROUTES.ADMINS_PATH}${id ? `/${id}` : ''}`);
  }

  useEffect(() => {
    load({ filter: {} });
  }, []);

  function afterSave(data: Admin) {
    updateAdmin(data);
    if (adminId === 'new') {
      setDetails(data.id);
    }
  }

  if (items.length === 0) {
    return <EmptyList error={error} loading={loading} />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button variant="contained" size="medium" onClick={() => setDetails('new')}>
                  Crate Admin
                </Button>
              </Grid>
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          <AdminsList items={items} onRowClick={setDetails} columns={columns} />
          <Loader loading={loading} />
          {!loading && !error && left && items.length > 1 && <Waypoint onEnter={() => load({ cursor, filter: {} })} />}
        </Paper>
      </Grid>
      {adminId && (
        <AdminDetails id={adminId} onClose={() => setDetails()} onCreate={afterSave} onUpdate={updateAdmin} />
      )}
    </Grid>
  );
}

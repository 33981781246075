import React, { FC } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { Field, FieldProps } from 'formik';

interface ICBoxProps extends CheckboxProps {
  label?: any;
  wrapperClassName?: string;
}

export const Cbox: FC<ICBoxProps> = ({ label, className, wrapperClassName, ...rest }) => (
  <div className={wrapperClassName}>
    <FormControlLabel
      className={className}
      control={<Checkbox {...rest} />}
      label={label}
      style={{ textTransform: 'capitalize' }}
    />
  </div>
);

export function CheckboxField({ name, ...props }: ICBoxProps) {
  return <Field name={name}>{({ field }: FieldProps) => <Cbox checked={field.value} {...props} {...field} />}</Field>;
}

import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CssBaseline from '@material-ui/core/CssBaseline';
import { theme } from './theme';
import { SnackbarProvider } from 'notistack';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SignIn } from '@bo/layouts';
import { setAuthHeader } from '@bo/api';
import { getStorageItem } from '@bo/utils/storageUtils';
import { ACCESS_TOKEN_KEY } from '@bo/constants';

export const App: React.FC = () => {
  const notistackRef: any = React.createRef();
  const onClickDismiss = (key: any) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  const { isAuthorized } = useSelector((state: any) => state);

  if (isAuthorized) {
    setAuthHeader(getStorageItem(ACCESS_TOKEN_KEY));
  }

  const renderPage = () => {
    if (isAuthorized) return <Outlet />;
    else return <SignIn />;
  };

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <CssBaseline />
        <SnackbarProvider
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          hideIconVariant={false}
          ref={notistackRef}
          action={(key) => (
            <IconButton onClick={onClickDismiss(key)}>
              <CloseIcon style={{ color: 'white' }} />
            </IconButton>
          )}
        >
          {renderPage()}
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export interface Admin {
  id: string;
  email: string;
  enabled: boolean;
  roles: AdminRole[];
  scope: AdminScope[];
  createdAt: Date;
  updatedAt: Date;
}

export enum AdminScope {
  BO = 'bo',
  CRC = 'crc',
}

export interface AccessPolicy {
  roleName: string;
  appId: string;
  object: string;
  action: string;
}

export interface PolicySaveRequest {
  policies: AccessPolicy[];
}

export interface AdminRole {
  id: string;
  name: string;
  appId: string;
  baseRole: boolean;
  permissions: AccessPolicy[];
  createdAt: Date;
}

export interface RoleSaveRequest {
  id?: string;
  name: string;
  appId: string;
}

export interface AdminListRequest {
  filter: {
    enabled?: boolean;
  };
}

export interface AdminSaveRequest {
  id?: string;
  email: string;
  enabled: boolean;
  roles?: string[];
  password?: string;
}

export interface PolicyList {
  objects: { [key: string]: string };
  actions: { [key: string]: string };
}

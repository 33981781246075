import { useSaveAdmin, useUpdateAdmin } from '@bo/api';
import { CheckboxField, InputField, Loader, SelectField } from '@bo/components';
import { useFormStyles } from '@bo/hooks';
import { useInputStyles } from '@bo/hooks/useInputStyles';
import { Admin, AdminRole, AdminScope } from '@bo/types';
import { composeValidators, formikValidator, isEmail, length, required } from '@bo/validators';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import clsx from 'clsx';

import { FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';

interface IProps {
  admin: Admin;
  roles: AdminRole[];
  onCreate: (data: Admin) => void;
  onUpdate: (data: Admin) => void;
  view: boolean;
}

export default function AdminOverviewTab({ admin, onCreate, view, roles, onUpdate }: IProps) {
  const formClasses = useFormStyles();
  const inputClasses = useInputStyles();
  const [changePasswordForm, setChangePasswordForm] = useState(false);
  const [save, saving] = view ? useUpdateAdmin((res) => onUpdate(res)) : useSaveAdmin((res) => onCreate(res));

  if (!admin) {
    return <Typography align="center">No data to display</Typography>;
  }

  return (
    <Loader loading={saving}>
      <Formik
        initialValues={{
          field1: admin.email,
          enabled: admin.enabled || false,
          otp: '',
          roles: admin.roles?.map((r) => r.id) || [],
          scope: admin.scope || [AdminScope.BO],
        }}
        validateOnMount={true}
        validate={(values) =>
          formikValidator({
            field1: composeValidators(values.field1, required, isEmail),
            otp: !view || values.otp.length ? composeValidators(values.otp, required, length(6, 20)) : undefined,
          })
        }
        onSubmit={async (values) => {
          const data = {
            email: values.field1?.trim(),
            id: admin.id,
            password: values.otp.length ? values.otp : undefined,
            roles: values.roles,
            enabled: values.enabled,
            scope: values.scope,
          };
          save(data);
        }}
      >
        {({ values, isSubmitting, isValid }) => {
          return (
            <Form className={formClasses.root}>
              <div className={clsx(formClasses.row, !view && inputClasses.emailInput)}>
                <InputField name="field1" label="Еmаil" autoComplete="off" />
              </div>
              {changePasswordForm && (
                <Typography variant="body1" color="primary">
                  Set new password
                </Typography>
              )}
              {(!view || changePasswordForm) && (
                <div className={formClasses.row}>
                  <InputField
                    name="otp"
                    label={changePasswordForm ? 'New pаsswоrd' : 'Pаsswоrd'}
                    type="password"
                    autoComplete="new-password"
                  />
                </div>
              )}
              <CheckboxField name="enabled" label="Active" />
              <SelectField
                name="scope"
                label="Scope"
                options={Object.values(AdminScope).map((v) => ({ value: v, label: v }))}
                fullWidth
                multi
              />
              <div className={formClasses.endBlock} />
              <Typography variant="body1" color="primary">
                Roles
              </Typography>
              <FieldArray
                name="roles"
                render={(arrayHelpers) => (
                  <div className={clsx(formClasses.row, 'wrap')}>
                    {roles.map((r) => (
                      <CheckboxField
                        name="roles"
                        label={r.name}
                        value={r.id}
                        checked={values.roles.includes(r.id)}
                        inputProps={{
                          onChange: (e: any) => {
                            if (e.target.checked) {
                              arrayHelpers.push(r.id);
                            } else {
                              const idx = values.roles.indexOf(r.id);
                              arrayHelpers.remove(idx);
                            }
                          },
                          // checked: values.roles.includes(r.id),
                        }}
                        key={r.id}
                      />
                    ))}
                  </div>
                )}
              />
              {view && !changePasswordForm && admin.enabled && (
                <div className={formClasses.actions}>
                  <Button type="button" variant="contained" size="large" onClick={() => setChangePasswordForm(true)}>
                    Change password
                  </Button>
                </div>
              )}
              <div className={formClasses.actions}>
                <Button
                  type="submit"
                  disabled={isSubmitting || !isValid || !values.roles.length}
                  variant="contained"
                  size="large"
                >
                  Save
                </Button>
                {changePasswordForm && (
                  <Button
                    type="button"
                    variant="contained"
                    size="large"
                    onClick={() => {
                      setChangePasswordForm(false);
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </Loader>
  );
}

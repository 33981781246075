import { useEffect, useRef, useState } from 'react';

import { Form, Formik } from 'formik';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { composeValidators, formikValidator, isString, length, required } from '@bo/validators';
import { DetailsPopup, BoolLabel, InputField, DataRow, DateView, Loader, CheckboxField } from '@bo/components';
import { AdminRole, AccessPolicy } from '@bo/types';
import { useRoleDetails, useSaveRole, usePolicyList, useAddPermissions, useRemovePermissions } from '@bo/api';
import { useFormStyles } from '@bo/hooks';

interface IProps {
  id: string;
  onClose: () => void;
  onSave: (data: AdminRole) => void;
}

export function RoleDetails({ onClose, id, onSave }: IProps) {
  const [{ data: role }, loading, load] = useRoleDetails();
  const [{ data: policies }, loadingPolicies, loadPolicies] = usePolicyList();
  const [addPermissions, adding] = useAddPermissions(() => null);
  const [updateRole, setUpdateRole] = useState(false);
  const [clearPermissions, clearing] = useRemovePermissions(() => null);
  const addPermissionsRef = useRef<AccessPolicy[]>([]);
  const clearPermissionsRef = useRef<AccessPolicy[]>([]);
  const newRolePermissionsCountRef = useRef(0);
  const [save, saving] = useSaveRole(async (res: any) => {
    setUpdateRole(true);
    if (addPermissionsRef.current.length) {
      await addPermissions({ policies: addPermissionsRef.current });
      addPermissionsRef.current = [];
    }
    if (clearPermissionsRef.current.length) {
      await clearPermissions({ policies: clearPermissionsRef.current });
      clearPermissionsRef.current = [];
    }
    onSave(res);
    load({ id });
    setUpdateRole(false);
  });
  const formClasses = useFormStyles();

  useEffect(() => {
    if (id !== 'new') {
      load({ id });
    }
  }, [id]);

  useEffect(() => {
    loadPolicies();
  }, []);

  const policyValues: { [key: string]: { READ: boolean; WRITE: boolean; REMOVE: boolean } } = {};

  for (const o of Object.values(policies.objects)) {
    policyValues[o] = {
      READ: !!role?.permissions?.find((p) => p.object === o && p.action === 'READ'),
      WRITE: !!role?.permissions?.find((p) => p.object === o && p.action === 'WRITE'),
      REMOVE: !!role?.permissions?.find((p) => p.object === o && p.action === 'REMOVE'),
    };
  }

  const onPermissionToggle = (e: any) => {
    id === 'new' && e.target.checked ? newRolePermissionsCountRef.current++ : newRolePermissionsCountRef.current--;
  };

  const sessionPermissions = sessionStorage.getItem('permissions');

  if (!role) {
    return <Typography align="center">No data to display</Typography>;
  }
  return (
    <DetailsPopup loading={loading || loadingPolicies || updateRole} onClose={onClose}>
      <>
        <Typography variant="h6" color="primary">
          {id === 'new' ? (
            'Create Role'
          ) : (
            <>
              {role.name}&nbsp;&nbsp;
              {role.baseRole && <BoolLabel value size="small" labels={['Base Role']} />}
            </>
          )}
        </Typography>
        <Loader loading={saving}>
          {role && (
            <Formik
              initialValues={{
                name: role.name,
                permissions: sessionPermissions ? JSON.parse(sessionPermissions) : policyValues,
              }}
              validate={(values) =>
                formikValidator({
                  name: composeValidators(values.name, required, isString, length(1, 30)),
                })
              }
              onSubmit={(values, { setSubmitting }) => {
                const data = {
                  name: values.name,
                  id: role.id,
                  appId: 'bo',
                };
                const { permissions, name: roleName } = values;
                sessionStorage.setItem('permissions', JSON.stringify(permissions));
                Object.keys(permissions).forEach((object) => {
                  const read: AccessPolicy = { roleName, appId: 'bo', object, action: 'READ' };
                  const readExist = role.permissions?.find((p) => p.object === object && p.action === 'READ');
                  const write: AccessPolicy = { roleName, appId: 'bo', object, action: 'WRITE' };
                  const writeExist = role.permissions?.find((p) => p.object === object && p.action === 'WRITE');
                  const remove: AccessPolicy = { roleName, appId: 'bo', object, action: 'REMOVE' };
                  const removeExist = role.permissions?.find((p) => p.object === object && p.action === 'REMOVE');
                  if (permissions[object].READ && !readExist) {
                    addPermissionsRef.current.push(read);
                  } else if (readExist && !permissions[object].READ) {
                    clearPermissionsRef.current.push(read);
                  }
                  if (permissions[object].WRITE && !writeExist) {
                    addPermissionsRef.current.push(write);
                  } else if (writeExist && !permissions[object].WRITE) {
                    clearPermissionsRef.current.push(write);
                  }
                  if (permissions[object].REMOVE && !removeExist) {
                    addPermissionsRef.current.push(remove);
                  } else if (removeExist && !permissions[object].REMOVE) {
                    clearPermissionsRef.current.push(remove);
                  }
                });
                save(data);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, isValid, values }) => {
                return (
                  <Form className={formClasses.topSpace}>
                    <div className={formClasses.row}>
                      <InputField name="name" label="Role Name" />
                    </div>
                    {role.createdAt && (
                      <DataRow label="Created at">
                        <DateView value={role.createdAt} />
                      </DataRow>
                    )}
                    <Typography color="primary" variant="h6">
                      Permissions
                    </Typography>
                    {Object.keys(values.permissions).map((k) => (
                      <Grid container justifyContent="space-between" alignItems="center" key={k}>
                        <Typography variant="body1" style={{ width: 150 }}>
                          {k}
                        </Typography>
                        <CheckboxField
                          label="READ"
                          name={`permissions.${k}.READ`}
                          defaultChecked={values.permissions[k].READ}
                          disabled={role.baseRole}
                          inputProps={{
                            onClick: onPermissionToggle,
                          }}
                        />
                        <CheckboxField
                          label="WRITE"
                          name={`permissions.${k}.WRITE`}
                          defaultChecked={values.permissions[k].WRITE}
                          disabled={role.baseRole}
                          inputProps={{
                            onClick: onPermissionToggle,
                          }}
                        />
                        <CheckboxField
                          label="REMOVE"
                          name={`permissions.${k}.REMOVE`}
                          defaultChecked={values.permissions[k].REMOVE}
                          disabled={role.baseRole}
                          inputProps={{
                            onClick: onPermissionToggle,
                          }}
                        />
                      </Grid>
                    ))}
                    <div className={formClasses.actions}>
                      <Button
                        type="submit"
                        disabled={
                          (id === 'new' && newRolePermissionsCountRef.current <= 0) ||
                          isSubmitting ||
                          !isValid ||
                          adding ||
                          clearing
                        }
                        variant="contained"
                        size="large"
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
          {!role && <Typography align="center">No data to display</Typography>}
        </Loader>
      </>
    </DetailsPopup>
  );
}

import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { SelectField } from '@bo/components';
import { Form, Formik } from 'formik';
import { formikValidator, required } from '@bo/validators';
import { useFormStyles } from '@bo/hooks';
import { Button } from '@material-ui/core';
import { WalletItemUpdateRequest, WalletListItem } from '@bo/types';
import { JsonEditor } from 'json-edit-react';

interface IProps {
  onSave: (data: WalletItemUpdateRequest) => void;
  saving: boolean;
  item: WalletListItem;
}

export function UpdateWalletConfig({ onSave, saving, item }: IProps) {
  const formClasses = useFormStyles();
  const [newConfig, setNewConfig] = useState(item.config);

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: 24 }}>
        <Typography variant="h6" color="primary">
          Update wallet config
        </Typography>
      </Grid>

      <Formik
        initialValues={{ id: item.id, config: item.config } as WalletItemUpdateRequest}
        onSubmit={() => {
          const data = { id: item.id, config: newConfig } as WalletItemUpdateRequest;
          onSave(data);
        }}
      >
        {({ isValid }) => {
          return (
            <Form className={formClasses.root}>
              <div className={formClasses.actions}>
                <Button type="submit" disabled={saving || !isValid} variant="contained" size="large">
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>

      <JsonEditor
        data={item.config}
        showErrorMessages={true}
        theme={['candyWrapper']}
        setData={(config) => setNewConfig(config)}
        maxWidth={900}
      />
    </>
  );
}

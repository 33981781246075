/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useBacktestAssetList } from '@bo/api';
import { IColumn, useColumns, useGlobalStyles } from '@bo/hooks';
import { BacktesAssetListRequest, BacktestAssetListItem } from '@bo/types';
import { TableList, TableListContentType, DateView, Loader } from '@bo/components';
import { Button, TableCell } from '@material-ui/core';
import { Waypoint } from 'react-waypoint';
import { useNavigate, useParams } from 'react-router';
import { ROUTES } from '@bo/constants';
import { SearchForm } from './SearchForm';
import { formatPnL, getDexscreenerLink } from '../common';

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'id', active: true },
  { id: 'chain', name: 'chain', active: true },
  { id: 'status', name: 'status', active: true },
  { id: 'symbol', name: 'symbol', active: true },
  { id: 'name', name: 'name', active: true },
  { id: 'total_pnl', name: 'total_pnl', active: true },
  { id: 'total_pnl_percent', name: 'total_pnl_percent', active: true },
  { id: 'r_pnl', name: 'r_pnl', active: true },
  { id: 'r_pnl_percent', name: 'r_pnl_percent', active: true },
  { id: 'unr_pnl', name: 'unr_pnl', active: true },
  { id: 'unr_pnl_percent', name: 'unr_pnl_percent', active: true },
  { id: 'actions', name: 'actions', active: true, cellProps: { align: 'center' } },
];

type BacktestState = { backtest: BacktestAssetListItem; method: string };

export const AssetPage = () => {
  const classes = useGlobalStyles();
  const [columns, ColumnsFilter] = useColumns('backtests', defaultColumns);
  const [{ data: items, error, cursor, left }, loading, load] = useBacktestAssetList();
  const navigate = useNavigate();
  const { backtestId } = useParams();

  const [filter, setFilter] = useState<BacktesAssetListRequest>({
    statusList: ['BUY_DONE', 'SELL_DONE', 'PNL_DONE'],
    // @ts-expect-error skip
    assetId: null,
  });
  const [backtestData, setBacktestData] = useState({} as BacktestState);

  useEffect(() => {
    // @ts-expect-error skip
    load({ ...filter, backtestId });
  }, [filter]);

  const handleRowClick = () => {};

  function setDetails(data: BacktestState) {
    console.log(data.backtest);
    if (data.method === 'open' && data.backtest) {
      navigate(`${ROUTES.MEME_STRATEGY_BACKTEST_ASSET_ONE}/${data.backtest.backtest_id}/assets/${data.backtest.id}`);
    }
  }

  const content: TableListContentType<BacktestAssetListItem> = {
    id: (i) => i.id,
    chain: (i) => i.chain,
    status: (i) => i.status,
    symbol: (i) => (
      <a target="_blank" href={getDexscreenerLink(i.chain, i.token)} rel="noreferrer">
        {i.symbol}
      </a>
    ),
    name: (i) => i.name,
    total_pnl: (i) => formatPnL(i.total_pnl),
    total_pnl_percent: (i) => formatPnL(i.total_pnl_percent, true),
    r_pnl: (i) => formatPnL(i.r_pnl),
    r_pnl_percent: (i) => formatPnL(i.r_pnl_percent, true),
    unr_pnl: (i) => formatPnL(i.unr_pnl),
    unr_pnl_percent: (i) => formatPnL(i.unr_pnl_percent, true),
    actions: (i) => (
      <TableCell key={'actions'}>
        <Button
          variant="contained"
          size="small"
          onClick={() => setDetails({ backtest: i, method: 'open' })}
          style={{ marginRight: '10px', height: '17px' }}
        >
          open
        </Button>
      </TableCell>
    ),
  };

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <SearchForm initialValues={filter} setFilter={setFilter} />
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justifyContent="space-between">
              <Grid item></Grid>
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          <>
            <TableList<BacktestAssetListItem>
              items={items}
              columns={columns}
              content={content}
              onRowClick={handleRowClick}
            />
            {left && cursor && (
              <Waypoint
                onEnter={() => {
                  // @ts-expect-error skip
                  load({ ...filter, backtestId, cursor });
                }}
              />
            )}
          </>
          <Loader loading={loading} />
        </Paper>
      </Grid>
    </Grid>
  );
};

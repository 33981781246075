import { Api } from './api';
import { useFetch, useSend } from '@bo/hooks';
import { LOCAL_ENV } from '@bo/constants';
import { EmptyRequest, AdminRole, ApiOneRequest, RoleSaveRequest, PolicyList, PolicySaveRequest } from '@bo/types';

export function useRolesList() {
  const data: AdminRole[] = [];
  return useFetch<EmptyRequest, AdminRole[], AdminRole>(
    async () => Api.post(`${LOCAL_ENV ? '' : '/backoffice'}/roles/list`, {}),
    data
  );
}

export function useRoleDetails() {
  return useFetch<ApiOneRequest, AdminRole>(
    async (data) => Api.post(`${LOCAL_ENV ? '' : '/backoffice'}/roles/one`, data),
    {} as AdminRole
  );
}

export function useSaveRole(cb: (data: AdminRole) => void) {
  return useSend<RoleSaveRequest, AdminRole>(
    async (data) => Api.post(`${LOCAL_ENV ? '' : '/backoffice'}/roles/${data.id ? 'update' : 'add'}`, data),
    cb,
    undefined,
    undefined,
    { UQ_role_name: 'Name is already registered in the system' }
  );
}

export function usePolicyList() {
  const data: PolicyList = { objects: {}, actions: {} };
  return useFetch<EmptyRequest, PolicyList>(
    async () => Api.post(`${LOCAL_ENV ? '' : '/backoffice'}/policy/list`, {}),
    data
  );
}

export function useAddPermissions(cb: () => void) {
  return useSend<PolicySaveRequest, any>(
    async (data) => Api.post(`${LOCAL_ENV ? '' : '/backoffice'}/policy/add`, data),
    cb
  );
}

export function useRemovePermissions(cb: () => void) {
  return useSend<PolicySaveRequest, any>(
    async (data) => Api.post(`${LOCAL_ENV ? '' : '/backoffice'}/policy/remove`, data),
    cb
  );
}

/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-empty-function */
import Grid from '@material-ui/core/Grid';
import { useGlobalStyles } from '@bo/hooks';
import Markdown from 'react-markdown';
import { useEffect, useState } from 'react';

export const DocsPage = () => {
  const classes = useGlobalStyles();

  const markdownList = [
    '/docs/meme_strategy/gerenal_workers.md',
    '/docs/meme_strategy/memes_fun.md',
    '/docs/meme_strategy/memes_strategy.md',
  ];

  const [markdownContent, setMarkdownContent] = useState('');
  const [markdownFile, setMarkdownFile] = useState('');

  useEffect(() => {
    fetch(markdownFile)
      .then((response) => response.text())
      .then((text) => setMarkdownContent(text))
      .catch(() => {});
  }, [markdownFile]);

  return (
    <div>
      <div>
        <h2>Markdown clickable list</h2>
      </div>

      <div>
        {markdownList.map((el) => (
          <ul
            onClick={() => setMarkdownFile(el)}
            style={{ fontSize: '16px', fontWeight: el === markdownFile ? '600' : '300', cursor: 'pointer' }}
            key={el}
          >
            - {el.split('/')[el.split('/').length - 1]}
          </ul>
        ))}
      </div>

      {markdownFile ? <Markdown>{markdownContent}</Markdown> : null}
    </div>
  );
};

import React from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  root: {
    'borderRadius': '2px',
    'background': theme.palette.divider,
    '&.success': {
      background: theme.palette.success.main,
    },
    '&.primary': {
      background: theme.palette.primary.dark,
    },
    '&.warning': {
      background: theme.palette.warning.main,
    },
    '&.danger': {
      background: theme.palette.secondary.main,
    },
  },
}));

export type TLabelType = 'success' | 'warning' | 'danger' | 'primary';

interface ILabelProps {
  type?: TLabelType;
  label: string;
  icon?: any;
  size?: 'small' | 'medium';
}

export function Label({ type, ...rest }: ILabelProps) {
  const classes = useStyles();

  return <Chip className={clsx(classes.root, type)} {...rest} />;
}

import { useDispatch } from 'react-redux';

import { Formik, Form } from 'formik';

import { Button } from '@material-ui/core';
import { InputField } from '@bo/components/Form';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { AdminScope } from '@bo/types';
import { composeValidators, formikValidator, required, length, isLoginEmail } from '@bo/validators';
import { loginAction } from '@bo/store/actions';
import { use2FAuth } from '@bo/api';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export function SignIn() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [auth2FA] = use2FAuth((res) => {
    dispatch(loginAction(res));
  });

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          LaoDeFi BO
        </Typography>
        <Formik
          initialValues={{ email: '', password: '', otpCode: '' }}
          validate={(values) =>
            formikValidator({
              email: composeValidators(values.email, required, isLoginEmail),
              password: composeValidators(values.password, required, length(6, 20)),
            })
          }
          onSubmit={(values) => {
            const data = {
              password: values.password,
              email: values.email.trim(),
              otpCode: values.otpCode,
              scopes: [AdminScope.BO],
            };
            auth2FA(data);
          }}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <InputField type="email" name="email" label="Email Address" />
              <InputField type="password" name="password" label="Password" />
              <Button
                type="submit"
                disabled={isSubmitting || !isValid}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
}

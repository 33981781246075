import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DetailsPopup, ConfirmationDialog, BoolLabel, DataRow, DateView } from '@bo/components';
import { useUserDetails, useAccountSettingsUpdate } from '@bo/api';

interface IProps {
  id: string;
  onClose: () => void;
}

export function UserDetails({ onClose, id }: IProps) {
  const [confirmTester, setConfirmTester] = useState(false);
  const [{ data: user }, loading, load, update] = useUserDetails();
  const [updateSettings, updatingSettings] = useAccountSettingsUpdate((accountSettings) => {
    update({ ...user, accountSettings });
    setConfirmTester(false);
  });

  useEffect(() => {
    load({ id });
  }, [id]);

  if (!user) {
    return <Typography align="center">No data to display</Typography>;
  }

  return (
    <DetailsPopup loading={loading} onClose={onClose}>
      <>
        <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: 24 }}>
          <Typography variant="h6" color="primary">
            {user.id}
          </Typography>
        </Grid>
        {confirmTester && (
          <ConfirmationDialog
            onConfirm={() =>
              updateSettings({
                accountId: id,
                settings: { ...user.accountSettings, isTester: !user.accountSettings?.isTester },
              })
            }
            onCancel={() => setConfirmTester(false)}
            sending={updatingSettings}
          >
            <DialogContentText>
              Confirm {user.accountSettings?.isTester ? 'disable' : 'enable'} testing mode for user ID {id}?
            </DialogContentText>
          </ConfirmationDialog>
        )}
        <DataRow label="Public key" copy={user.publicKey}>
          {user.publicKey.substring(0, 60)}...
        </DataRow>
        <DataRow label="Seed Phrase Imported">
          <BoolLabel value={user.isSeedImported} />
        </DataRow>
        <DataRow label="Testing Mode" labelWidth={138}>
          <Switch checked={user.accountSettings?.isTester} onClick={() => setConfirmTester(true)} />
        </DataRow>
        <DataRow label="Language">{user.accountSettings?.language}</DataRow>
        <DataRow label="Base Currency">{user.accountSettings?.currencyId}</DataRow>
        <DataRow label="Favorite Token">{user.accountSettings?.favoriteTokenId}</DataRow>
        <DataRow label="Registered At">
          <DateView value={user.createdAt} />
        </DataRow>
      </>
    </DetailsPopup>
  );
}

import { Api } from '../admin/api';
import { useFetch, useSend } from '@bo/hooks';
import { TplListRequest, TplListItem, SettingListRequest, SettingListItem, SettingItemUpdateRequest } from '@bo/types';
import { apiPrefix } from '@bo/constants';

export function useSettingList() {
  return useFetch<SettingListRequest, SettingListItem[], SettingListItem>(
    async (data) => Api.post(`${apiPrefix.memeStrategy}/settings/list`, data),
    []
  );
}

export function useSettingsUpdate(cb: (data: SettingListItem) => void) {
  return useSend<SettingItemUpdateRequest, SettingListItem>(
    async (data) => Api.post(`${apiPrefix.memeStrategy}/settings/update`, data),
    cb,
    'Settings updated'
  );
}

export function useTplList() {
  return useFetch<TplListRequest, TplListItem[], TplListItem>(
    async (data) => Api.post(`${apiPrefix.memeStrategy}/token_pool_lake/list`, data),
    []
  );
}

export function useTplStatusList() {
  return useFetch<any, string[], string>(
    async (data) => Api.post(`${apiPrefix.memeStrategy}/token_pool_lake/list/status`, data),
    []
  );
}

export function useChainList() {
  return useFetch<any, string[], string>(
    async (data) => Api.post(`${apiPrefix.memeStrategy}/wallets/list/chain`, data),
    []
  );
}

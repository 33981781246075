import MuiOutlinedInput from './MuiOutlinedInput';
import MuiTableCell from './MuiTableCell';
import MuiInputLabel from './MuiInputLabel';
import MuiFormHelperText from './MuiFormHelperText';

export const overrides = {
  MuiOutlinedInput,
  MuiInputLabel,
  MuiTableCell,
  MuiFormHelperText,
};

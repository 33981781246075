import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: ({ width }: any) => width || 'calc(100vw - 320px)',
    padding: theme.spacing(2),
    height: ({ height }: any) => height || '100%',
  },
}));

interface ILoaderProps {
  loading: boolean;
  children?: any;
  width?: number;
  height?: number;
}

export function Loader({ loading, children, width, height }: ILoaderProps) {
  if (!loading) {
    return children || null;
  }
  const classes = useStyles({ width, height });
  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
}

import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import { Field, FieldProps } from 'formik';

interface IInputProps extends Partial<OutlinedTextFieldProps> {
  name: string;
  type?: string;
  label?: any;
  forceValidation?: boolean;
}

export function InputField({ name, type, multiline, margin, forceValidation = false, ...rest }: IInputProps) {
  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => {
        const error = (meta.touched || forceValidation) && !!form.errors[name];
        return (
          <TextField
            type={type || 'text'}
            variant="outlined"
            margin={margin || 'normal'}
            fullWidth
            multiline={multiline}
            rows={multiline ? '3' : undefined}
            error={error}
            helperText={error ? String(form.errors[name]) : undefined}
            {...rest}
            {...field}
          />
        );
      }}
    </Field>
  );
}

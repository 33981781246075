import makeStyles from '@material-ui/core/styles/makeStyles';

export const useInputStyles = makeStyles((theme) => ({
  root: {},
  emailInput: {
    'marginBottom': theme.spacing(0),
    'display': 'flex',
    'alignItems': 'flex-start',
    '& > *': {
      'flex': '1 1 0px',
      'marginRight': `${theme.spacing(2)}px !important`,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

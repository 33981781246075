import axios from 'axios';
import { AUTH_HEADER, REACT_APP_API_URL } from '@bo/constants';
import { removeAllTokensFromStorage } from '@bo/utils/storageUtils';

export type { ApiPromise, ApiCustomPromise } from '@bo/types';

export const Api = axios.create({
  baseURL: REACT_APP_API_URL,
});

Api.interceptors.response.use(undefined, function (error) {
  if (error?.response?.status === 401 && error?.response?.data?.error.error !== 'Unauthorized') {
    removeAllTokensFromStorage();
    removeAuthHeader();
    setTimeout(() => window.location.reload(), 5000);
  }
  return Promise.reject(error);
});

export const setAuthHeader = (token: string | null) => {
  if (token !== null) {
    Api.defaults.headers.common[AUTH_HEADER] = `Bearer ${token}`;
  }
};

export const removeAuthHeader = () => {
  delete Api.defaults.headers.common[AUTH_HEADER];
};

import React, { cloneElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

interface ITabPanelProps {
  children?: any;
  index: number;
  value: number;
  className?: any;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...rest } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...rest}
    >
      {children}
    </Typography>
  );
}

function a11yProps(index: number) {
  return {
    'id': `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    boxShadow: 'none',
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.primary.dark}`,
  },
  title: {
    fontSize: 12,
    minWidth: 30,
  },
  tab: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

interface ITabsPanelProps {
  tabs: any[];
  activeTab?: number;
  onTabChange?: any;
}

export function TabsPanel({ tabs, activeTab, onTabChange }: ITabsPanelProps) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: number) => {
    if (typeof onTabChange === 'function') {
      onTabChange(tabs[newValue].value || tabs[newValue].title);
    }
    setValue(newValue);
  };

  useEffect(() => {
    if (activeTab) {
      setValue(activeTab);
    }
  }, [activeTab]);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.tabs}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs panel" indicatorColor="primary">
          {tabs.map((t, i) => (
            <Tab label={t.title} {...a11yProps(i)} key={t.title} disabled={t.disabled} className={classes.title} />
          ))}
        </Tabs>
      </AppBar>
      {tabs.map((t, i) =>
        value === i ? (
          <TabPanel value={value} index={i} key={t.title} className={classes.tab}>
            {cloneElement(t.content, { active: value === i })}
          </TabPanel>
        ) : null
      )}
    </div>
  );
}

import React from 'react';
import { Label } from './Label';

interface IProps {
  value?: boolean;
  size?: 'small' | 'medium';
  labels?: string[];
}

export function BoolLabel({ value, size, labels }: IProps) {
  const positive = labels ? labels[0] : 'YES';
  const negative = labels ? labels[1] : 'NO';
  return <Label label={value ? positive : negative} type={value ? 'success' : 'warning'} size={size || 'small'} />;
}

import { useLayoutEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTokensRefresh } from '@bo/api';
import { getStorageItem } from '@bo/utils/storageUtils';
import { setAuthTokens } from '@bo/utils/authUtils';
import { REFRESH_TOKEN_KEY, REFRESH_TOKEN_EXPIRE_KEY, ACCESS_TOKEN_EXPIRE_KEY } from '@bo/constants';
import { logOutAction, refreshAction } from '@bo/store/actions';

export function useRefreshToken() {
  const refreshTimeout = useRef<any>();
  const dispatch = useDispatch();
  const [sendRefresh] = useTokensRefresh((data) => {
    setAuthTokens(data);
    dispatch(refreshAction(false));
  });

  const refresh = async () => {
    dispatch(refreshAction(true));
    const refreshToken = getStorageItem(REFRESH_TOKEN_KEY);
    const refreshTokenExp = parseInt(getStorageItem(REFRESH_TOKEN_EXPIRE_KEY) ?? '0');

    if (!refreshToken || refreshTokenExp < Date.now()) {
      dispatch(logOutAction());
      return;
    }
    await sendRefresh({ refreshToken });
    const accessTokenExp = parseInt(getStorageItem(ACCESS_TOKEN_EXPIRE_KEY) ?? '0');
    refreshTimeout.current = setTimeout(() => refresh(), accessTokenExp - Date.now() - 5000);
  };

  useLayoutEffect(() => {
    const accessTokenExp = parseInt(getStorageItem(ACCESS_TOKEN_EXPIRE_KEY) ?? '0');
    if (accessTokenExp <= Date.now()) {
      refresh();
    } else {
      refreshTimeout.current = setTimeout(() => refresh(), accessTokenExp - Date.now() - 5000);
    }

    return () => {
      if (!refreshTimeout.current) {
        return;
      }
      clearTimeout(refreshTimeout.current);
    };
  }, []);
}

import React, { useEffect } from 'react';
import { DetailsPopup } from '@bo/components';
import { useBacktestAdd, useBacktestUpdate } from '@bo/api';
import { BacktestListItem } from '@bo/types';
import { BacktestAdd } from './BacktestAdd';
import { BacktestUpdate } from './BacktestUpdate';

interface IProps {
  method: string;
  item?: BacktestListItem;
  onSave: () => void;
  onClose: () => void;
}

export function BacktestPopup({ method, onSave, onClose, item }: IProps) {
  const updateCb = () => onSave();
  const [update, updating] = useBacktestUpdate(updateCb);

  const saveCb = () => onSave();
  const [save, saving] = useBacktestAdd(saveCb);

  useEffect(() => {
    if (!item) return;
  }, [item]);

  const render = () => {
    switch (method) {
      case 'add':
        return <BacktestAdd saving={saving} onSave={save} />;
      case 'update':
        return item ? <BacktestUpdate saving={updating} onSave={update} item={item} /> : null;
      default:
        return null;
    }
  };

  return <DetailsPopup onClose={onClose}>{render()}</DetailsPopup>;
}

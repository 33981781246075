import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { ApiPromise } from '@bo/api';
import { getErrFromResponse } from '@bo/helpers';

export function useSend<Req, Res>(
  remote: (requestData: Req, ...args: any) => ApiPromise<Res>,
  callback: (data: Res) => void,
  successMessage?: string,
  errorHandler?: (data: any) => void,
  errorsTexts?: { [key: string]: string }
): [(requestData: Req, ...args: any) => void, boolean] {
  const [updating, setUpdating] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const req = async (requestData: Req, ...args: any) => {
    setUpdating(true);

    try {
      const { data } = await remote(requestData, ...args);
      if (data.error) {
        return enqueueSnackbar(data.error.message || data.error.error, {
          variant: 'error',
        });
      }
      sessionStorage.removeItem('permissions');
      if (successMessage) {
        enqueueSnackbar(successMessage, { variant: 'success' });
      }
      callback(data.data);
    } catch (e: any) {
      let err = e.toString();
      if (e.response && e.response.data && e.response.data.error) {
        err = getErrFromResponse(e);
        errorHandler && errorHandler(e.response.data);
        if (errorsTexts) {
          const errors = Object.keys(errorsTexts);
          for (let k = 0, l = errors.length; k < l; k++) {
            if (err.includes(errors[k])) {
              err = errorsTexts[errors[k]];
              break;
            }
          }
        }
      }
      enqueueSnackbar(err, { variant: 'error' });
    }

    setUpdating(false);
  };

  return [req, updating];
}

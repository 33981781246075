import { IAuthResponse, Admin, IGenerate2FAuthResponse, ISaveUserCredentials } from '@bo/types';

export const saveUserCredentials = (data: ISaveUserCredentials) => ({
  type: 'SAVE_USER_CREDENTIALS',
  data,
});
export const generate2FAuthDataAction = (data: IGenerate2FAuthResponse) => ({
  type: 'USER_GENERATE_2FA_AUTH_DATA',
  data,
});
export const loginAction = (data: IAuthResponse) => ({
  type: 'USER_LOGIN',
  data,
});
export const accountInfoAction = (data: Admin) => ({
  type: 'USER_INFO',
  data,
});
export const logOutAction = () => ({ type: 'USER_LOGOUT' });

export const generateOTPAction = () => ({ type: 'USER_GENERATE_OTP' });

export const refreshAction = (refreshing: boolean) => ({ type: 'TOKENS_REFRESH', refreshing });

import { Api } from './api';
import { useFetch, useSend } from '@bo/hooks';
import {
  UsersListRequest,
  User,
  UserListItem,
  ApiOneRequest,
  UpdateAccountSettingsRequest,
  AccountSettings,
} from '@bo/types';
import { apiPrefix } from '@bo/constants';

export function useUsersList() {
  return useFetch<UsersListRequest, UserListItem[], UserListItem>(
    async (data) => Api.post(`${apiPrefix.backoffice}/users/list`, data),
    []
  );
}

export function useUserDetails() {
  return useFetch<ApiOneRequest, User, User>(
    async (data) => Api.post(`${apiPrefix.backoffice}/users/one`, data),
    new User()
  );
}

export function useAccountSettingsUpdate(cb: (data: AccountSettings) => void) {
  return useSend<UpdateAccountSettingsRequest, AccountSettings>(
    async (data) => Api.post(`${apiPrefix.backoffice}/users/settings/update`, data),
    cb
  );
}

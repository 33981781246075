export const required = (value: any) =>
  value !== undefined && value !== null && value.toString().length > 0 ? undefined : 'Required field';

export const arrRequired = (value: any[]) => (value.length ? undefined : 'Required field');

export const composeValidators = (value: any, ...validators: any[]) => {
  const errs = validators.map((validator) => validator(value)).filter((r) => r !== undefined);
  return errs.length ? errs.join('. ') : undefined;
};

const emailRegexp =
  /^(([^<>()[\]\\.,;:!&%#'\s@"]+(\.[^<>()[\]\\.,;:!&%#'\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmail = (value: string) =>
  value && (emailRegexp.test(value.trim().toLowerCase()) ? undefined : 'Invalid email');

export const isLoginEmail = (value: string) =>
  value && (emailRegexp.test(value.trim().toLowerCase()) ? undefined : 'Please enter a valid email address');

export const isString = (value: string) =>
  value ? (/^\w+$/.test(value) ? undefined : 'Accepts only letters and digits') : undefined;

export const lengthRequired = (l: number) => (value: any) =>
  value && value.length !== l ? `Must be ${l} characters length` : undefined;

export const isDomain = (value: any) => {
  if (!value) return;
  const pattern = new RegExp('^(https?:\\/\\/)((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|)$', 'i');
  return !pattern.test(value) ? 'Must be valid URL' : undefined;
};

export const isURL = (value: any) => {
  if (!value) return;
  const pattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+!@#$%^&*()_;:-]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !pattern.test(value) ? 'Must be valid URL' : undefined;
};

export const maxValue = (max: number) => (value: any) => parseFloat(value) > max ? `Maximum is ${max}` : undefined;
export const minValue = (min: number) => (value: any) => parseFloat(value) < min ? `Minimum is ${min}` : undefined;

export const mustBeNumber = (value: any) => (isNaN(value) ? 'Must be a number' : undefined);

export const maxLength = (max: number) => (value: any) =>
  !!value && value.length > max ? `${max} symbols maximum` : undefined;

export const length = (min: number, max: number) => (value: any) =>
  !!value && (value.length < min || value.length > max) ? `From ${min} to ${max} symbols` : undefined;

// export const isLink = (str: string) => {
//   const linkRegexp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})\/$/
//   return linkRegexp.test(str) ? undefined : 'Must be a valid link with / on the end'
// }

export const isLink = (str?: string | null, withTrailingSlash?: boolean) => {
  if (!str) return;
  const trailingSlash = withTrailingSlash ? '/' : '';
  const pattern = new RegExp(
    '^((http|ws)s?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+#]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      `(\\#[-a-z\\d_]*)?${trailingSlash}$`,
    'i'
  ); // fragment locator

  let message = 'Must be a valid link';
  if (withTrailingSlash) {
    message += ' with / on the end';
  }
  return pattern.test(str) ? undefined : message;
};

import { FC } from 'react';
import { Form, Formik } from 'formik';
import { InputField, SelectField } from '@bo/components';
import { Button } from '@material-ui/core';
import { useFormStyles, useGlobalStyles } from '@bo/hooks';
import { BacktesAssetListRequest } from '@bo/types';

interface ITplSearchFormProps {
  setFilter: (filter: BacktesAssetListRequest) => void;
  initialValues: BacktesAssetListRequest;
}

export const SearchForm: FC<ITplSearchFormProps> = ({ initialValues, setFilter }) => {
  const formClasses = useFormStyles();
  const classes = useGlobalStyles();

  const statusList = ['NEW', 'BUY_DONE', 'SELL_DONE', 'PNL_DONE'];

  return (
    <div className={classes.searchForm}>
      <Formik
        onSubmit={(values: BacktesAssetListRequest) => {
          setFilter({ ...values });
        }}
        initialValues={initialValues}
      >
        {() => (
          <Form>
            <div className={formClasses.row}>
              <div>
                <InputField name="assetId" label="asset id" />
              </div>
              <div style={{ paddingTop: '10px' }}>
                <SelectField
                  name="statusList"
                  label="status list"
                  options={statusList.map((v) => ({ value: v, label: v }))}
                  fullWidth
                  multi
                  noEmptyOption
                  margin="dense"
                />
              </div>

              <div style={{ marginTop: '8px', textAlign: 'right', maxWidth: '100px' }}>
                <Button type="submit" variant="contained" size="large">
                  Apply
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

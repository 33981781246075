import { AdminRole } from '@bo/types';
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { RolesList } from './RolesList';
import { RoleDetails } from './Details';
import { EmptyList } from '@bo/components/EmptyList';
import { Loader } from '@bo/components/Loader';
import { useGlobalStyles, IColumn } from '@bo/hooks';
import { useRolesList } from '@bo/api';
import { ROUTES } from '@bo/constants';

const columns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'name', name: 'Name', active: true },
  { id: 'base', name: 'Base role', active: true },
  { id: 'created', name: 'Created at', active: true },
];

export function RolesPage() {
  const classes = useGlobalStyles();
  const navigate = useNavigate();
  const { roleId } = useParams();
  const [{ data: items, error }, loading, load, updateRole] = useRolesList();

  function setDetails(id?: string) {
    navigate(`${ROUTES.ROLES_PATH}${id ? `/${id}` : ''}`);
  }

  useEffect(() => {
    load();
  }, []);

  function afterSave(data: AdminRole) {
    updateRole(data);
    if (roleId === 'new') {
      setDetails(data.id);
    }
  }

  if (items.length === 0) {
    return <EmptyList error={error} loading={loading} />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button variant="contained" size="medium" onClick={() => setDetails('new')}>
                  Crate new role
                </Button>
              </Grid>
            </Grid>
          </CardActions>
          <RolesList items={items} onRowClick={setDetails} columns={columns} />
          <Loader loading={loading} />
        </Paper>
      </Grid>
      {roleId && (
        <RoleDetails
          id={roleId}
          onClose={() => {
            sessionStorage.removeItem('permissions');
            setDetails();
          }}
          onSave={afterSave}
        />
      )}
    </Grid>
  );
}

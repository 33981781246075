import { DateView } from '@bo/components';
import { IColumn, useGlobalStyles } from '@bo/hooks';
import { UserListItem } from '@bo/types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';

interface IProps {
  items: UserListItem[];
  columns: IColumn[];
  onRowClick: any;
}

const content: { [key: string]: (i: UserListItem, key: number) => void } = {
  id: (i, key) => <TableCell key={key}>{i.id}</TableCell>,
  pKey: (i, key) => <TableCell key={key}>{i.publicKey.substring(0, 100)}...</TableCell>,
  createdAt: (i, key) => (
    <TableCell key={key}>
      <DateView value={i.createdAt} />
    </TableCell>
  ),
};

export function UsersList({ items, onRowClick, columns }: IProps) {
  const classes = useGlobalStyles();

  return (
    <Table size="small">
      <TableHead>
        <TableRow>{columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}</TableRow>
      </TableHead>
      <TableBody>
        <>
          {items.map((i) => (
            <TableRow key={i.id} className={classes.pointer} onClick={() => onRowClick(i.id)} hover>
              <>{columns.map((c, index) => content[c.id](i, index))}</>
            </TableRow>
          ))}
        </>
      </TableBody>
    </Table>
  );
}

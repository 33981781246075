import { setAllTokensToStorage, removeAllTokensFromStorage } from '../storageUtils';
import { IAuthResponse } from '@bo/types';
import { setAuthHeader, removeAuthHeader } from '@bo/api';

export const setAuthTokens = (tokens: IAuthResponse) => {
  setAllTokensToStorage(tokens);
  setAuthHeader(tokens.accessToken);
};

export const removeAuthTokens = () => {
  removeAllTokensFromStorage();
  removeAuthHeader();
};

import update from 'immutability-helper';
import { ACCESS_TOKEN_KEY } from '@bo/constants';
import { setAuthTokens, removeAuthTokens } from '@bo/utils/authUtils';
import { getStorageItem } from '@bo/utils/storageUtils';

const initialState = {
  user: {},
  authData2FA: {},
  userCredentials: {},
  isProcessing2FA: false,
  isAuthorized: !!getStorageItem(ACCESS_TOKEN_KEY),
  refreshing: false,
};

export function common(state = initialState, action: any) {
  switch (action.type) {
    case 'SAVE_USER_CREDENTIALS':
      return update(state, {
        userCredentials: { $set: action.data },
      });
    case 'USER_GENERATE_2FA_AUTH_DATA':
      return update(state, {
        authData2FA: { $set: action.data },
        isProcessing2FA: { $set: true },
      });
    case 'USER_LOGIN':
      setAuthTokens(action.data);
      return update(state, {
        isAuthorized: { $set: true },
      });
    case 'USER_INFO':
      return update(state, {
        user: { $set: action.data },
      });
    case 'TOKENS_REFRESH':
      return update(state, {
        refreshing: { $set: action.refreshing },
      });
    case 'USER_LOGOUT':
      removeAuthTokens();
      return update(state, {
        user: { $set: {} },
        isAuthorized: { $set: false },
      });
    default:
      return state;
  }
}

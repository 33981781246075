import React, { useEffect, useState } from 'react';
import { Outlet, NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import clsx from 'clsx';
import { useAccountInfo, useLogout } from '@bo/api';
import { accountInfoAction, logOutAction } from '@bo/store/actions';
import { MainMenu } from '@bo/components';
import { useRefreshToken } from '@bo/hooks';
import { getPageTitle } from '@bo/helpers';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    justifyContent: 'space-between',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    background: blue[200],
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: theme.palette.getContrastText(blue[200]),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    minWidth: '100%',
  },
  fixedHeight: {
    height: 240,
  },
  env: {
    'height': 18,
    'fontSize': 10,
    'display': 'flex',
    'alignItems': 'center',
    'textTransform': 'uppercase',
    'padding': '0 5px',
    'borderRadius': 10,
    'background': theme.palette.divider,
    '&.dev': {
      background: theme.palette.warning.main,
    },
    '&.prod': {
      background: theme.palette.success.main,
    },
  },
  account: {
    'display': 'flex',
    'alignItems': 'center',
    '& .name': {
      fontSize: 14,
    },
  },
}));

export const MainLayout: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [getAccountInfo] = useAccountInfo((res) => {
    dispatch(accountInfoAction(res));
  });
  const [open, setOpen] = useState(true);
  const { user } = useSelector((state: any) => state);
  useRefreshToken();

  useEffect(() => {
    if (!user.id) {
      getAccountInfo(null);
    }
  }, [user]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [logout] = useLogout(() => dispatch(logOutAction()));

  const title = getPageTitle(pathname);

  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap className={classes.title}>
            {title}
          </Typography>
          <div className={classes.account}>
            <Link underline={'none'} className="name" color="inherit">
              {user.email || ''}
            </Link>
            <IconButton color="inherit" onClick={() => logout(null)}>
              <ExitToApp />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <div>
            <Typography variant="h6">LaoDeFi BO</Typography>
            {/*<Timer />*/}
          </div>
          <div className={clsx(classes.env, process.env.REACT_APP_WIDGET_ENV)}>
            {process.env.REACT_APP_WIDGET_ENV || 'Local'}
          </div>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MainMenu />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          <Outlet />
        </Container>
      </main>
    </div>
  );
};

import { Api } from '../admin/api';
import { useFetch, useSend } from '@bo/hooks';
import {
  UsersListRequest,
  UserListItem,
  WalletHistoryListItem,
  WalletHistoryListRequest,
  WalletListItem,
  WalletItemUpdateRequest,
  WalletListRequest,
} from '@bo/types';
import { apiPrefix } from '@bo/constants';

export function useWalletList() {
  return useFetch<WalletListRequest, WalletListItem[], WalletListItem>(
    async (data) => Api.post(`${apiPrefix.memeStrategy}/wallets/list`, data),
    []
  );
}

export function useWalletUpdate(cb: (data: WalletListItem) => void) {
  return useSend<WalletItemUpdateRequest, WalletListItem>(
    async (data) => Api.post(`${apiPrefix.memeStrategy}/wallets/update`, data),
    cb,
    'Wallet updated'
  );
}

export function useWalletConfigUpdate(cb: (data: WalletListItem) => void) {
  return useSend<WalletItemUpdateRequest, WalletListItem>(
    async (data) => Api.post(`${apiPrefix.memeStrategy}/wallets/update`, data),
    cb,
    'Wallet config updated'
  );
}

export function useWalletHistoryList() {
  return useFetch<WalletHistoryListRequest, WalletHistoryListItem[], WalletHistoryListItem>(
    async (data) => Api.post(`${apiPrefix.memeStrategy}/wallets/history/list`, data),
    []
  );
}

export function useWalletPnlList() {
  return useFetch<UsersListRequest, UserListItem[], UserListItem>(
    async (data) => Api.post(`${apiPrefix.memeStrategy}/wallets/pnl/list`, data),
    []
  );
}

import { LOCAL_ENV } from '@bo/constants/env';

export const AUTH_HEADER = 'Authorization';

export const apiPrefix = {
  backoffice: LOCAL_ENV ? 'http://localhost:4000' : '/backoffice',
  memeStrategy: LOCAL_ENV ? 'http://localhost:4001' : '/meme-strategy',
};

export const DEFAULT_LIST_LIMIT = 50;

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Loader } from '../Loader';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    height: '100vh',
    width: 630,
    top: 0,
    right: 0,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    background: theme.palette.background.paper,
    overflowY: 'auto',
    overflowX: 'hidden',
    transition: 'width 300ms ease-out 0s',
  },
  close: {
    position: 'absolute',
    right: 630,
    top: '8px',
  },
}));

export interface IDetailsProps {
  children?: any;
  onClose: any;
  loading?: boolean;
  width?: number;
}

export function DetailsPopup({ children, onClose, loading, width }: IDetailsProps) {
  const classes = useStyles({ width });

  return (
    <Modal open onClose={onClose}>
      <>
        <IconButton onClick={onClose} className={classes.close} style={{ right: width }}>
          <CloseIcon />
        </IconButton>
        <div className={classes.root} style={{ width }}>
          <Loader loading={!!loading} width={560}>
            {children}
          </Loader>
        </div>
      </>
    </Modal>
  );
}
